import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Nav from "../../navigation/Nav";
import Menu from "../../navigation/Menu";
import "moment/locale/nl";
import { AiOutlineLeft } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import "../../../scss/customers/create_customer.scss";
import "../../../scss/customers/customer.scss";
import Swal from "sweetalert2";

// API functions
import { create_customer, upload_image } from "../../../functions/api.js";

function CreateCustomer() {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [objects, setObjects] = useState([]);

  const [customerData, setCustomerData] = useState({
    telefoon: "",
    email: "",
    website: "",
    klantnaam: "",
    bedrijfsnaam: "",
    adres: "",
    huisnummer: "",
    postcode: "",
    plaats: "",
  });

  // Submit on enter key
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "Enter") {
        event.preventDefault();
        submitData();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  const handleCustomerInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prev) => ({ ...prev, [name]: value }));
  };

  const addNewObject = () => {
    setObjects((prevObjects) => [
      ...prevObjects,
      {
        naam: "",
        nummer: "",
        telefoonnummer: "",
        bouwjaar: "",
        locatie: {
          adres: customerData.adres,
          huisnummer: customerData.huisnummer,
          postcode: customerData.postcode,
          plaats: customerData.plaats,
        },
        contactpersoon: {
          naam: customerData.klantnaam,
          telefoon: customerData.telefoon,
          email: customerData.email,
        },
        media: {
          mediaid: "",
          naam: "",
          url: "",
          website: "",
        },
      },
    ]);
  };

  const removeObject = (index) => {
    setObjects((prevObjects) => prevObjects.filter((_, i) => i !== index));
  };

  const handleObjectInputChange = (index, field, value, nestedField = null) => {
    setObjects((prevObjects) => {
      const updatedObjects = [...prevObjects];

      if (nestedField) {
        updatedObjects[index][nestedField] = {
          ...updatedObjects[index][nestedField],
          [field]: value,
        };
      } else {
        updatedObjects[index][field] = value;
      }

      return updatedObjects;
    });
  };

  const handleImageUpload = async (event, index) => {
    const img = event.target.files[0];

    if (!img) return;

    try {
      const data = await upload_image(img);
      if (data && data.response) {
        handleObjectInputChange(index, "media", data.response);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      Swal.fire({
        title: "Fout bij uploaden",
        text: "Er is een fout opgetreden bij het uploaden van de afbeelding.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const submitData = async () => {
    setIsSaving(true);

    try {
      const formattedObjects = objects.map((object) => ({
        ...object,
        contactpersoon: {
          ...object.contactpersoon,
          contactpersoonid: 0,
        },
        locatie: {
          ...object.locatie,
          locatieid: 0,
          locatienaam: "",
        },
        klantid: "",
        objectid: "",
      }));

      const customerPayload = {
        id: "",
        telefoon: customerData.telefoon || "",
        email: customerData.email || "",
        website: customerData.website || "",
        klantnaam: customerData.klantnaam || "",
        bedrijfsnaam: customerData.bedrijfsnaam || "",
        locatie: {
          locatieid: 0,
          locatienaam: "",
          adres: customerData.adres || "",
          huisnummer: customerData.huisnummer || "",
          postcode: customerData.postcode || "",
          plaats: customerData.plaats || "",
        },
        objecten: formattedObjects,
        toch_aanmaken: false,
      };

      const response = await create_customer(customerPayload);

      if (response.response) {
        navigate("/customers");
      } else if (response.message) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success mx-2",
            cancelButton: "btn btn-danger mx-2",
          },
          buttonsStyling: false,
        });

        swalWithBootstrapButtons
          .fire({
            title: "Pas op!",
            text: response.message,
            showCancelButton: true,
            confirmButtonText: "Toch aanmaken",
            cancelButtonText: "Annuleren",
            reverseButtons: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              customerPayload.toch_aanmaken = true;
              await create_customer(customerPayload);
              navigate("/customers");
            }
          });
      } else {
        Swal.fire({
          title: "Er is iets fout gegaan",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      Swal.fire({
        title: "Er is iets fout gegaan",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="select-customer-container-main">
      <Nav page="Rapportages" />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="create-customer">
          <Link
            className="fixed bottom-0 left-0 ml-4 mb-4"
            to="/customers"
            style={{
              textDecoration: "none",
              color: "#757575",
              width: "85px",
            }}
          >
            <p className="text-base flex flex-row">
              <AiOutlineLeft className="mt-1" />
              terug
            </p>
          </Link>

          <h1 className="customer-header mt-12 md:mt-10 lg:mt-8">Klant</h1>
          <hr className="hr2" />

          <div>
            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="klant-naam">
                  Klant
                </label>
                <div className="input-color">
                  <input
                    type="text"
                    id="klant-naam"
                    name="klantnaam"
                    value={customerData.klantnaam}
                    onChange={handleCustomerInputChange}
                    autoComplete="on"
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="bedrijfs-naam">
                  Bedrijfsnaam
                </label>
                <div className="input-color">
                  <input
                    type="text"
                    id="bedrijfs-naam"
                    name="bedrijfsnaam"
                    value={customerData.bedrijfsnaam}
                    onChange={handleCustomerInputChange}
                    autoComplete="on"
                  />
                </div>
              </div>
            </div>

            <div className="customer-input-section">
              <label className="label-customer" htmlFor="adres-straat">
                Adres (straat)
              </label>
              <div className="input-color">
                <input
                  type="text"
                  id="adres-straat"
                  name="adres"
                  value={customerData.adres}
                  onChange={handleCustomerInputChange}
                  autoComplete="on"
                />
              </div>
            </div>

            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="post-code">
                  Postcode
                </label>
                <div className="input-color">
                  <input
                    type="text"
                    id="post-code"
                    name="postcode"
                    value={customerData.postcode}
                    onChange={handleCustomerInputChange}
                    autoComplete="on"
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="huisnummer">
                  Huisnummer
                </label>
                <div className="input-color">
                  <input
                    type="text"
                    name="huisnummer"
                    value={customerData.huisnummer}
                    onChange={handleCustomerInputChange}
                    autoComplete="on"
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="plaats">
                  Plaats
                </label>
                <div className="input-color">
                  <input
                    type="text"
                    name="plaats"
                    value={customerData.plaats}
                    onChange={handleCustomerInputChange}
                    autoComplete="on"
                  />
                </div>
              </div>
            </div>

            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="telefoon">
                  Telefoon
                </label>
                <div className="input-color">
                  <input
                    type="text"
                    name="telefoon"
                    value={customerData.telefoon}
                    onChange={handleCustomerInputChange}
                    autoComplete="on"
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="email">
                  Email
                </label>
                <div className="input-color">
                  <input
                    type="text"
                    name="email"
                    value={customerData.email}
                    onChange={handleCustomerInputChange}
                    autoComplete="on"
                  />
                </div>
              </div>
            </div>

            <div className="customer-input-section">
              <label className="label-customer" htmlFor="website">
                Website
              </label>
              <div className="input-color">
                <input
                  type="text"
                  name="website"
                  value={customerData.website}
                  onChange={handleCustomerInputChange}
                  autoComplete="on"
                />
              </div>
            </div>

            <div className="object-wrapper">
              <div
                className="new-object"
                style={{ cursor: "pointer" }}
                onClick={addNewObject}
              >
                <BsPlusLg size={25} />
                <div className="new-object-text">
                  <h2>Nieuw object</h2>
                </div>
              </div>
            </div>

            <hr className="hr2" />

            {objects.length > 0 && (
              <div>
                <h1 className="customer-header">Object</h1>
                {objects.map((object, index) => (
                  <div key={index}>
                    <div className="remove-customer">
                      <button
                        className="submit-customer"
                        onClick={() => removeObject(index)}
                      >
                        Verwijder
                      </button>
                    </div>

                    <div className="input-section-row">
                      <div className="input-wrapper">
                        <label className="label-customer">
                          Object Foto (optioneel) (JPEG,JPG,PNG)
                        </label>
                        <div
                          id={`media-wrapper-${index}`}
                          className="input-color"
                        >
                          <input
                            type="file"
                            accept=".jpeg, .png, .jpg"
                            onChange={(event) =>
                              handleImageUpload(event, index)
                            }
                            id={`customer-media-id-${index}`}
                            aria-label="File browser example"
                          />
                          <span className="file-custom" />
                        </div>
                      </div>
                    </div>

                    <div className="input-section-row">
                      <div className="input-wrapper">
                        <label
                          className="label-customer"
                          htmlFor={`objecten-naam-${index}`}
                        >
                          Object Naam
                        </label>
                        <div className="input-color">
                          <input
                            type="text"
                            id={`objecten-naam-${index}`}
                            value={object.naam}
                            onChange={(e) =>
                              handleObjectInputChange(
                                index,
                                "naam",
                                e.target.value
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label
                          className="label-customer"
                          htmlFor={`object-nummer-${index}`}
                        >
                          Object nummer
                        </label>
                        <div className="input-color">
                          <input
                            type="text"
                            id={`object-nummer-${index}`}
                            value={object.nummer}
                            onChange={(e) =>
                              handleObjectInputChange(
                                index,
                                "nummer",
                                e.target.value
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="customer-input-section">
                      <label
                        className="label-customer"
                        htmlFor={`adres-straat-${index}`}
                      >
                        Adres
                      </label>
                      <div className="input-color">
                        <input
                          type="text"
                          id={`adres-straat-${index}`}
                          value={object.locatie.adres}
                          onChange={(e) =>
                            handleObjectInputChange(
                              index,
                              "adres",
                              e.target.value,
                              "locatie"
                            )
                          }
                          autoComplete="on"
                        />
                      </div>
                    </div>

                    <div className="input-section-row">
                      <div className="input-wrapper">
                        <label
                          className="label-customer"
                          htmlFor={`huisnummer-${index}`}
                        >
                          Huisnummer
                        </label>
                        <div className="input-color">
                          <input
                            type="text"
                            id={`huisnummer-${index}`}
                            value={object.locatie.huisnummer}
                            onChange={(e) =>
                              handleObjectInputChange(
                                index,
                                "huisnummer",
                                e.target.value,
                                "locatie"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label
                          className="label-customer"
                          htmlFor={`post-code-${index}`}
                        >
                          Postcode
                        </label>
                        <div className="input-color">
                          <input
                            type="text"
                            id={`post-code-${index}`}
                            value={object.locatie.postcode}
                            onChange={(e) =>
                              handleObjectInputChange(
                                index,
                                "postcode",
                                e.target.value,
                                "locatie"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label
                          className="label-customer"
                          htmlFor={`plaats-${index}`}
                        >
                          Vestigingsplaats
                        </label>
                        <div className="input-color">
                          <input
                            type="text"
                            id={`plaats-${index}`}
                            value={object.locatie.plaats}
                            onChange={(e) =>
                              handleObjectInputChange(
                                index,
                                "plaats",
                                e.target.value,
                                "locatie"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="input-section-row">
                      <div className="input-wrapper">
                        <label
                          className="label-customer"
                          htmlFor={`telefoonnummer-${index}`}
                        >
                          Telefoon
                        </label>
                        <div className="input-color">
                          <input
                            type="text"
                            id={`telefoonnummer-${index}`}
                            value={object.telefoonnummer}
                            onChange={(e) =>
                              handleObjectInputChange(
                                index,
                                "telefoonnummer",
                                e.target.value
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label
                          className="label-customer"
                          htmlFor={`bouwjaar-${index}`}
                        >
                          Bouwjaar
                        </label>
                        <div className="input-color">
                          <input
                            type="text"
                            id={`bouwjaar-${index}`}
                            value={object.bouwjaar}
                            onChange={(e) =>
                              handleObjectInputChange(
                                index,
                                "bouwjaar",
                                e.target.value
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                    </div>

                    <h1 className="customer-header">Contactgegevens</h1>
                    <div className="input-section-row">
                      <div className="input-wrapper">
                        <label
                          className="label-customer"
                          htmlFor={`contactpersoon-naam-${index}`}
                        >
                          Naam
                        </label>
                        <div className="input-color">
                          <input
                            type="text"
                            id={`contactpersoon-naam-${index}`}
                            value={object.contactpersoon.naam}
                            onChange={(e) =>
                              handleObjectInputChange(
                                index,
                                "naam",
                                e.target.value,
                                "contactpersoon"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label
                          className="label-customer"
                          htmlFor={`contactpersoon-telefoon-${index}`}
                        >
                          Telefoon
                        </label>
                        <div className="input-color">
                          <input
                            type="text"
                            id={`contactpersoon-telefoon-${index}`}
                            value={object.contactpersoon.telefoon}
                            onChange={(e) =>
                              handleObjectInputChange(
                                index,
                                "telefoon",
                                e.target.value,
                                "contactpersoon"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label
                          className="label-customer"
                          htmlFor={`contactpersoon-email-${index}`}
                        >
                          Email
                        </label>
                        <div className="input-color">
                          <input
                            type="text"
                            id={`contactpersoon-email-${index}`}
                            value={object.contactpersoon.email}
                            onChange={(e) =>
                              handleObjectInputChange(
                                index,
                                "email",
                                e.target.value,
                                "contactpersoon"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="hr2" />
                  </div>
                ))}
              </div>
            )}

            <div style={{ height: "100px" }}>
              <br />
              <button
                style={{ marginBottom: 10 }}
                className="submit-customer"
                onClick={submitData}
                disabled={isSaving}
              >
                {isSaving ? "Bezig met aanmaken..." : "Aanmaken"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCustomer;
