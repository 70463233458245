import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

import { insert_instrument, delete_instrument } from "../../functions/api";

import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";

function MeetinstrumentForm() {
  const navigate = useNavigate();
  const location = useLocation();

  const initialInstrumentState = {
    meetapparatuurid: location.state?.meetinstrument?.meetapparatuurid ?? 0,
    naam: "",
    nummer: "",
    merk: "",
    type: "",
    serienummer: "",
    kalibratiedatum: new Date().toISOString().split("T")[0],
  };

  const [meetinstrument, setMeetinstrument] = useState(
    location.state?.meetinstrument ?? initialInstrumentState
  );
  const [isEditing, setIsEditing] = useState(
    !!location.state?.meetinstrument?.meetapparatuurid
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.state?.meetinstrument) {
      const instrument = location.state.meetinstrument;
      const formattedDate = instrument.kalibratiedatum
        ? new Date(instrument.kalibratiedatum).toISOString().split("T")[0]
        : initialInstrumentState.kalibratiedatum;

      setMeetinstrument({
        ...instrument,
        kalibratiedatum: formattedDate,
        nummer: String(instrument.nummer || ""),
      });
      setIsEditing(true);
    } else {
      setMeetinstrument(initialInstrumentState);
      setIsEditing(false);
    }
  }, [location.state]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMeetinstrument((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const saveData = async () => {
    setLoading(true);
    try {
      const dataToSend = {
        ...meetinstrument,
        nummer: Number(meetinstrument.nummer) || 0,
      };
      const response = await insert_instrument(dataToSend);

      if (response && response.ok) {
        Swal.fire({
          title: "Succes!",
          text: "Meetinstrument succesvol opgeslagen!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        navigate("/administration/meetinstrumenten");
      } else {
        throw new Error(response?.message || "Opslaan mislukt");
      }
    } catch (error) {
      console.error("Error saving instrument:", error);
      Swal.fire({
        title: "Fout!",
        text: `Opslaan van meetinstrument mislukt. ${error.message || ""}`,
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteInstrument = () => {
    if (!meetinstrument.meetapparatuurid) return;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2",
        cancelButton: "btn btn-danger mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Weet je zeker dat je dit meetinstrument wil verwijderen?",
        text: "Dit kan niet ongedaan worden gemaakt.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ja, Verwijder!",
        cancelButtonText: "Nee, Annuleer!",
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          try {
            const response = await delete_instrument(
              meetinstrument.meetapparatuurid
            );
            if (response && response.ok) {
              Swal.fire({
                title: "Verwijderd!",
                text: "Meetinstrument succesvol verwijderd.",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
              navigate("/administration/meetinstrumenten");
            } else {
              throw new Error(response?.message || "Verwijderen mislukt");
            }
          } catch (error) {
            console.error("Error deleting instrument:", error);
            Swal.fire({
              title: "Fout!",
              text: `Verwijderen van meetinstrument mislukt. ${
                error.message || ""
              }`,
              icon: "error",
            });
          } finally {
            setLoading(false);
          }
        }
      });
  };

  const labelStyle = "block text-sm font-medium text-gray-700 mb-1";
  const inputStyle =
    "block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-gray-100"; // Kept previous input style
  const formGroupStyle = "mb-4";

  return (
    <div className="select-report-container-main bg-gray-100 min-h-screen">
      <Nav
        page={
          isEditing ? "Meetinstrument Bewerken" : "Meetinstrument Toevoegen"
        }
      />
      <div className="page-wrap-row flex">
        <Menu className="menu-container" />
        <div className="ml-[37px] md:ml-[163px] lg:ml-[189px] mt-[62px] w-full p-6 text-black">
          <h1 className="text-2xl font-semibold mb-4">
            {isEditing ? "Meetinstrument Gegevens" : "Nieuw Meetinstrument"}
          </h1>
          <hr className="hr2 mb-6" />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
            <div className={formGroupStyle}>
              <label htmlFor="naam" className={labelStyle}>
                Naam instrument:
              </label>
              <input
                type="text"
                id="naam"
                name="naam"
                value={meetinstrument.naam}
                onChange={handleInputChange}
                className={inputStyle}
                disabled={loading}
              />
            </div>

            <div className={formGroupStyle}>
              <label htmlFor="nummer" className={labelStyle}>
                Nummer:
              </label>
              <input
                type="number"
                id="nummer"
                name="nummer"
                value={meetinstrument.nummer}
                onChange={handleInputChange}
                className={inputStyle}
                disabled={loading}
              />
            </div>

            <div className={formGroupStyle}>
              <label htmlFor="merk" className={labelStyle}>
                Merk:
              </label>
              <input
                type="text"
                id="merk"
                name="merk"
                value={meetinstrument.merk}
                onChange={handleInputChange}
                className={inputStyle}
                disabled={loading}
              />
            </div>

            <div className={formGroupStyle}>
              <label htmlFor="type" className={labelStyle}>
                Type:
              </label>
              <input
                type="text"
                id="type"
                name="type"
                value={meetinstrument.type}
                onChange={handleInputChange}
                className={inputStyle}
                disabled={loading}
              />
            </div>

            <div className={formGroupStyle}>
              <label htmlFor="serienummer" className={labelStyle}>
                Serienummer:
              </label>
              <input
                type="text"
                id="serienummer"
                name="serienummer"
                value={meetinstrument.serienummer}
                onChange={handleInputChange}
                className={inputStyle}
                disabled={loading}
              />
            </div>

            <div className={formGroupStyle}>
              <label htmlFor="kalibratiedatum" className={labelStyle}>
                Kalibratiedatum:
              </label>
              <input
                type="date"
                id="kalibratiedatum"
                name="kalibratiedatum"
                value={meetinstrument.kalibratiedatum}
                onChange={handleInputChange}
                className={`${inputStyle} appearance-none`}
                disabled={loading}
              />
            </div>
          </div>

          <hr className="hr2 mt-6 mb-4" />
          <div className="flex gap-4 justify-start items-center">
            <button
              type="button"
              onClick={saveData}
              disabled={loading}
              className="px-4 py-2 border rounded-md shadow-sm bg-green-500 text-white hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? "Opslaan..." : "Opslaan"}
            </button>
            <button
              type="button"
              onClick={() => navigate("/administration/meetinstrumenten")}
              disabled={loading}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              Terug
            </button>
            {isEditing && (
              <button
                type="button"
                onClick={handleDeleteInstrument}
                disabled={loading}
                className="px-4 py-2 border rounded-md shadow-sm bg-red-500 text-white hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed ml-auto" // Added ml-auto to push delete to the right
              >
                Verwijderen
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetinstrumentForm;
