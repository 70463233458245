//global variables
// const expressBaseUrl = "https://api-expr.ezinspect.nl/";
// const expressBaseUrl = "https://api-ezinspect.jitso-dev.nl/";
const expressBaseUrl = "https://api-ezinspect.jitso-dev.nl/";
// const expressBaseUrl = "http://localhost:3001/";

const apiBaseUrl = "https://ezinspect-api.jitso-dev.nl/api/v2/";

export async function get_inspecties() {
  return fetch(`${expressBaseUrl}get_all_inspecties`, {
    method: "GET",

    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Oeps.. dat ging even mis. [get inspecties]");
    });
}
export async function get_one_inspection(id) {
  return fetch(`${expressBaseUrl}get_single_inspection/${id}`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Oeps.. dat ging even mis. [get inspecties]");
    });
}

export async function update_inspectie(data) {
  return fetch(`${expressBaseUrl}update_inspectie`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Oeps.. dat ging even mis. [update inspectie]");
    });
}

export async function upload_image(file) {
  const formData = new FormData();
  formData.append("image", file);
  return fetch(`${apiBaseUrl}upload`, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      alert(JSON.stringify(error));
    });
}

export const upload_media = async (file) => {
  const formData = new FormData();
  formData.append("image", file);

  try {
    const response = await fetch(
      "https://ezinspect-api.jitso-dev.nl/api/v2/Upload",
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error(`Upload failed with status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error uploading media:", error);
    throw error;
  }
};

export async function register(form_data) {
  const formData = new FormData();
  formData.append("form_data", form_data[0]);
  return (
    fetch(`${expressBaseUrl}register`, {
      method: "POST",

      body: JSON.stringify(form_data[0]),
      // mode: 'cors',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      // .then((response) => { return response.json() })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      })
  );
}

export async function checkIfPaymentIdentificationIsValid(
  paymentIdentification
) {
  return fetch(
    `${expressBaseUrl}check_payment_identification?payment_identification=${paymentIdentification}`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {});
}

export async function get_all_instrumenten() {
  return fetch(`${expressBaseUrl}get_all_instrumenten`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function get_all_objecten() {
  return fetch(`${expressBaseUrl}get_all_objecten`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error.json();
    });
}

export async function insert_object(object) {
  return fetch(`${expressBaseUrl}insert_object`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function report_situatie(object) {
  return fetch(`${expressBaseUrl}report_gevaarlijke_situatie`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function update_object(object, id) {
  return fetch(`${expressBaseUrl}insert_object/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function delete_object(id) {
  return fetch(`${expressBaseUrl}delete_object/${id}`, {
    method: "DELETE",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Oeps.. dat ging even mis. [delete inspecties] \n" + error);
    });
}

export async function get_all_rapporten() {
  return fetch(`${expressBaseUrl}get_all_rapporten`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.json();
    });
}
export async function get_one_rapport(id) {
  return fetch(`${expressBaseUrl}get_one_rapport/` + id, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.json();
    });
}

export async function show_permissions() {
  return fetch(`${expressBaseUrl}show_permissions`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return console.error(error);
    });
}

export async function show_member_permissions(id) {
  console.log();
  return fetch(`${expressBaseUrl}show_member_permissions/` + id, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return console.error(error);
    });
}

export async function update_permissions(data) {
  console.log();
  return fetch(`${expressBaseUrl}update_permissions`, {
    method: "POST",
    credentials: "include",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return error;
    });
}

export async function insert_rapportage(object_rapportage) {
  return fetch(`${expressBaseUrl}insert_rapportage`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: object_rapportage,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}
export async function update_rapportage(object_rapportage) {
  return fetch(`${expressBaseUrl}update_rapportage`, {
    method: "POST",
    credentials: "include",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: object_rapportage,
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function insert_tekst(object_tekst) {
  return fetch(`${expressBaseUrl}insert_tekst`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object_tekst),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      console.error(error);
    });
}
export async function insert_instrument(object) {
  return fetch(`${expressBaseUrl}insert_instrument`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function update_instrument(object, id) {
  return fetch(`${expressBaseUrl}update_instrument/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function insert_norm(object) {
  return fetch(`${expressBaseUrl}insert_norm`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function update_norm(object, id) {
  return fetch(`${expressBaseUrl}update_norm/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function delete_tekst(id) {
  return fetch(`${expressBaseUrl}delete_tekst/${id}`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function delete_instrument(id) {
  return fetch(`${expressBaseUrl}delete_instrument/${id}`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function delete_norm(id) {
  return fetch(`${expressBaseUrl}delete_norm/${id}`, {
    method: "DELETE",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function get_all_teksten() {
  return fetch(`${expressBaseUrl}get_teksten`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function get_tekstenbieb(id = null) {
  const url = id
    ? `${expressBaseUrl}get_tekstenbieb/${id}`
    : `${expressBaseUrl}get_tekstenbieb`;

  return fetch(url, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function delete_tekstenbieb(id) {
  return fetch(`${expressBaseUrl}delete_tekstenbieb/${id}`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function delete_gebrek(id) {
  return fetch(`${expressBaseUrl}delete_gebrek/${id}`, {
    method: "DELETE",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function get_all_aansluitgegevens() {
  return fetch(`${expressBaseUrl}get_all_aansluitgegevens`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error.json();
    });
}

export async function insert_aansluitgegeven(object) {
  return fetch(`${expressBaseUrl}insert_aansluitgegeven`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function delete_aansluitgegeven(id) {
  return fetch(`${expressBaseUrl}delete_aansluitgegeven/${id}`, {
    method: "DELETE",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Oeps.. dat ging even mis. [delete inspecties] \n" + error);
    });
}

export async function get_all_normen() {
  return fetch(`${expressBaseUrl}get_all_normen`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error.json();
    });
}

export async function delete_rapportage(id) {
  return fetch(`${expressBaseUrl}delete_rapportage/${id}`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Oeps.. dat ging even mis. [delete inspecties] \n" + error);
    });
}

export async function login(username, password) {
  const login_data = {
    username: username,
    password: password,
  };
  return fetch(`${expressBaseUrl}login`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(login_data),
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
}

export async function logoutExpress() {
  return fetch(`${expressBaseUrl}logout`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function check_auth() {
  return fetch(`${expressBaseUrl}check_auth`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      return response.status;
    })
    .catch((error) => {});
}

export function check_forget_password_id(id) {
  if (id == null) {
    return;
  }
  return fetch(`${expressBaseUrl}check_forget_password_id`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  })
    .then((response) => {
      return response.status;
    })
    .catch((error) => {});
}

export function get_user() {
  return fetch(`${expressBaseUrl}get_user`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
}

export function subscribe_package(package_id) {
  return fetch(`${expressBaseUrl}subscribe`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ package_id: package_id }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {});
}

export function resetPassword(password1, password2, id) {
  return fetch(`${expressBaseUrl}resetPassword`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      password1: password1,
      password2: password2,
      id: id,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {});
}

export function update_subscribe_package(package_id) {
  return fetch(`${expressBaseUrl}update_subcription`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ package_id: package_id }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {});
}

export function forget_password(email) {
  return fetch(`${expressBaseUrl}forget_password`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ email: email }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {});
}

export async function check_invite_link(id, token) {
  return fetch(`${expressBaseUrl}check_invite_link/${id}/${token}`, {
    method: "GET",

    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {});
}

export async function register_team_member(id, token, data) {
  return fetch(`${expressBaseUrl}register_team_member/${id}/${token}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function invite_member(invitation_data) {
  return fetch(`${expressBaseUrl}invite_member`, {
    method: "POST",

    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(invitation_data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function disable_member(object) {
  return fetch(`${expressBaseUrl}disable_member`, {
    method: "POST",
    credentials: "include",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function update_member(object) {
  return fetch(`${expressBaseUrl}update_member`, {
    method: "POST",
    credentials: "include",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function create_customer(object) {
  return fetch(`${expressBaseUrl}klant`, {
    method: "POST",
    credentials: "include",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function delete_customer_object(id) {
  return fetch(`${expressBaseUrl}object_klant/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function delete_media(id) {
  return fetch(`${expressBaseUrl}delete_media/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function get_all_customers() {
  return fetch(`${expressBaseUrl}klant`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.json();
    });
}

export async function get_bijlagen(id) {
  return fetch(`${expressBaseUrl}get_bijlagen/${id}`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.json();
    });
}

export async function get_rapportage_teksten(id) {
  return fetch(`${expressBaseUrl}get_rapportage_teksten/${id}`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.json();
    });
}

export async function get_default_bijlagen() {
  return fetch(`${expressBaseUrl}get_default_bijlagen`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.json();
    });
}

export async function create_bijlagen(data) {
  return fetch(`${expressBaseUrl}create_bijlagen`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: data,
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return error;
    });
}

export async function getGebrek(id) {
  return fetch(`${expressBaseUrl}gebrek/${id}`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("Error fetching Gebrek:", error);
      return error;
    });
}

export async function getAllGebreken() {
  return fetch(`${expressBaseUrl}gebrek`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("Error fetching all Gebreken:", error);
      return error;
    });
}

export async function createGebrek(gebrekData) {
  return fetch(`${expressBaseUrl}gebrek`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(gebrekData),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("Error creating Gebrek:", error);
      return error;
    });
}

export async function updateGebrek(id, gebrekData) {
  return fetch(`${expressBaseUrl}gebrek/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(gebrekData),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("Error updating Gebrek:", error);
      return error;
    });
}

export async function deleteGebrek(id) {
  return fetch(`${expressBaseUrl}gebrek/${id}`, {
    method: "DELETE",
    credentials: "include",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("Error deleting Gebrek:", error);
      return error;
    });
}
