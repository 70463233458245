// src/pages/inspections/EditInspections.js
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Menu from "../../navigation/Menu";
import Nav from "../../navigation/Nav";
import InspectionDetails from "../components/InspectionDetails";
import GebrekenList from "../components/GebrekenList";
import { useInspection } from "../../../hooks/useInspection";

export default function EditInspections() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    inspection,
    isLoading,
    isSubmitting,
    error,
    fetchError,
    uploadErrors,
    gsUploadErrors,
    collapsedGebreken,
    gebrekRefs,
    newLocationInputs,
    copiedLocationsData,
    handleUpdateGebrek,
    handleGebrekChange,
    toggleGebrekCollapse,
    collapseAllGebreken,
    expandAllGebreken,
    addGebrek,
    deleteGebrek,
    createNewGebrekWithLocations,
    copyLocationsForPasting,
    pasteLocationsIntoGebrek,
    handleNewLocationInputChange,
    addLocationToGebrek,
    handleSetUploadError,
    handleSetGsUploadError,
    handleSubmit,
    copyEntireGebrek,
  } = useInspection(id);

  if (isLoading) {
    return (
      <p className="text-center mt-10 text-gray-600">Inspectie laden...</p>
    );
  }

  if (fetchError && !inspection) {
    return (
      <div className="select-report-container-main">
        <Nav page="Inspecties" />
        <div className="page-wrap-row">
          <Menu className="menu-container" />
          <div className="ml-40 px-20 w-full mt-20 text-black bg-gray-100 flex flex-col items-center space-y-4">
            <h1 className="text-3xl font-semibold text-red-600">Fout</h1>
            <p className="text-center text-red-500">{fetchError}</p>
            <button
              onClick={() => navigate("/inspections")}
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600"
            >
              Terug naar Inspecties
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!inspection) {
    return (
      <div className="select-report-container-main">
        <Nav page="Inspecties" />
        <div className="page-wrap-row">
          <Menu className="menu-container" />
          <div className="ml-40 px-20 w-full mt-20 text-black bg-gray-100 flex flex-col items-center space-y-4">
            <h1 className="text-3xl font-semibold text-center">
              Inspectie niet gevonden
            </h1>
            <p className="text-center text-gray-600">ID: {id} niet gevonden.</p>
            <button
              onClick={() => navigate("/inspections")}
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600"
            >
              Terug naar Inspecties
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="select-report-container-main">
      <Nav page="Inspecties" />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="ml-40 px-20 w-full mt-20 text-black bg-gray-100 flex flex-col space-y-8 pb-20">
          <div className="flex flex-row justify-between items-center">
            <h1 className="text-3xl font-semibold">Inspectie Bewerken</h1>
          </div>

          {error && (
            <div className="p-3 bg-red-100 border border-red-300 text-red-700 rounded">
              <strong>Fout bij opslaan:</strong> {error}
            </div>
          )}

          <InspectionDetails inspection={inspection} />

          <GebrekenList
            inspection={inspection}
            collapsedGebreken={collapsedGebreken}
            uploadErrors={uploadErrors}
            gsUploadErrors={gsUploadErrors}
            newLocationInputs={newLocationInputs}
            copiedLocationsData={copiedLocationsData}
            gebrekRefs={gebrekRefs}
            onAddGebrek={addGebrek}
            onDeleteGebrek={deleteGebrek}
            onToggleGebrekCollapse={toggleGebrekCollapse}
            onCollapseAllGebreken={collapseAllGebreken}
            onExpandAllGebreken={expandAllGebreken}
            onGebrekChange={handleGebrekChange}
            onLocationInputChange={handleNewLocationInputChange}
            onAddLocation={addLocationToGebrek}
            onCreateNewGebrekWithLocations={createNewGebrekWithLocations}
            onCopyLocationsForPasting={copyLocationsForPasting}
            onPasteLocationsIntoGebrek={pasteLocationsIntoGebrek}
            onUpdateGebrek={handleUpdateGebrek}
            onSetUploadError={handleSetUploadError}
            onSetGsUploadError={handleSetGsUploadError}
            copyEntireGebrek={copyEntireGebrek}
          />
          {/* Action Buttons */}
          <div className="fixed bottom-0 left-40 right-0 bg-gray-100 px-20 ml-20 mr-20 py-4 border-t flex justify-start space-x-4 z-10">
            <button
              type="button"
              onClick={handleSubmit}
              disabled={isSubmitting || isLoading}
              className={`px-8 py-2 text-white font-semibold rounded-md shadow-sm ${
                isSubmitting || isLoading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-red-500 hover:bg-red-600"
              }`}
            >
              {isSubmitting ? "Opslaan..." : "Opslaan"}
            </button>
            <button
              type="button"
              onClick={() => navigate("/inspections")}
              disabled={isSubmitting}
              className="px-8 py-2 bg-gray-200 text-gray-700 font-semibold rounded-md shadow-sm hover:bg-gray-300 disabled:opacity-50"
            >
              Annuleren
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
