import React from "react";

export const MediaItem = ({ mediaItem }) => {
  if (!mediaItem || !mediaItem.url) return null;

  const isImage =
    mediaItem.type?.startsWith("image/") ||
    /\.(jpg|jpeg|png|gif)$/i.test(mediaItem.url);
  const isVideo =
    mediaItem.type?.startsWith("video/") ||
    /\.(mp4|webm|ogg)$/i.test(mediaItem.url);

  return (
    <div className="relative group w-24 h-24 border rounded overflow-hidden shadow-sm">
      {isImage ? (
        <img
          src={mediaItem.url}
          alt={mediaItem.naam || "Media"}
          className="w-full h-full object-cover"
        />
      ) : isVideo ? (
        <video
          src={mediaItem.url}
          controls
          className="w-full h-full object-cover"
        >
          Your browser does not support the video tag.
        </video>
      ) : (
        <a
          href={mediaItem.url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center w-full h-full bg-gray-100 text-gray-600 text-xs p-1 break-all"
          title={mediaItem.naam || "Open media"}
        >
          {mediaItem.naam || "Bestand"}
        </a>
      )}
    </div>
  );
};
