import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { nl } from "date-fns/locale";
import { formatDateForInput } from "../../../utils/helpers";

const InspectionDetails = ({ inspection }) => {
  if (!inspection) return null;

  return (
    <section className="flex flex-col space-y-4 border rounded-lg p-6 bg-white shadow">
      <h2 className="text-xl font-semibold text-black mb-2 border-b pb-2">
        Inspectiegegevens
      </h2>
      <div className="flex flex-col space-y-1">
        <label className="text-sm font-semibold text-gray-700">
          Klantnaam:
        </label>
        <input
          type="text"
          value={inspection.klant?.naam || "N/A"}
          className="p-3 border rounded-md shadow-sm bg-gray-200 cursor-not-allowed"
          disabled
        />
      </div>
      <div className="flex flex-col space-y-1">
        <label className="text-sm font-semibold text-gray-700">
          Objectnaam:
        </label>
        <input
          type="text"
          value={inspection.object?.naam || "N/A"}
          disabled
          className="p-3 border rounded-md shadow-sm bg-gray-200 cursor-not-allowed"
        />
      </div>
      <div className="flex flex-col space-y-1">
        <label className="text-sm font-semibold text-gray-700">
          Startdatum:
        </label>
        <DatePicker
          selected={formatDateForInput(inspection.datumstart)}
          showTimeSelect
          placeholderText="Geen startdatum bekend"
          dateFormat="Pp"
          timeFormat="p"
          timeIntervals={15}
          className="p-3 border rounded-md shadow-sm bg-gray-200 focus:ring focus:ring-blue-300 w-full cursor-not-allowed"
          locale={nl}
          disabled
        />
      </div>
    </section>
  );
};

export default InspectionDetails;
