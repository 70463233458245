import React, { useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";

const ExtraBijlagen = ({ selectedExtraBijlagen, setSelectedExtraBijlagen }) => {
    const [extraBijlagen, setExtraBijlagen] = useState([]);

    async function handlePdfUpload(titel, file) {
        if (!titel || !file) return;

        try {
            const formData = new FormData();
            formData.append("pdf", file);

            const response = await fetch(
                "https://ezinspect-api.jitso-dev.nl/api/v2/UploadPDF",
                {
                    method: "POST",
                    body: formData,
                }
            );

            const jsonResponse = await response.json();

            const newObjExtraBijlagen = {
                label: titel,
                value: jsonResponse.pdf,
            };

            setExtraBijlagen([...extraBijlagen, newObjExtraBijlagen]);
            setSelectedExtraBijlagen([...selectedExtraBijlagen, newObjExtraBijlagen]);
        } catch (error) {
            console.error("Error uploading PDF:", error);
            Swal.fire({
                title: "Fout!",
                text: "Er is een fout opgetreden bij het uploaden van de PDF.",
                icon: "error",
            });
        }
    }

    const openBijlageModal = () => {
        Swal.fire({
            title: "Extra bijlage toevoegen",
            html: `
        <div class="flex flex-col space-y-4 p-2">
          <div class="flex flex-col">
            <label class="text-left text-sm font-medium text-gray-700 mb-1">Titel</label>
            <input 
              type="text" 
              id="swal-input-title" 
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Voer een titel in"
            />
          </div>
          
          <div class="flex flex-col">
            <label class="text-left text-sm font-medium text-gray-700 mb-1">PDF bestand</label>
            <div class="flex items-center justify-center w-full">
              <label class="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg class="w-8 h-8 mb-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                  </svg>
                  <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Klik om te uploaden</span> of sleep bestand hierheen</p>
                </div>
                <input 
                  id="swal-input-file" 
                  type="file" 
                  accept="application/pdf" 
                  class="hidden" 
                />
              </label>
            </div>
            <div id="file-name" class="mt-2 text-sm text-gray-500"></div>
          </div>
        </div>
      `,
            showCancelButton: true,
            confirmButtonText: "Toevoegen",
            cancelButtonText: "Annuleren",
            customClass: {
                confirmButton: "bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md mr-3",
                cancelButton: "bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-md",
                actions: "mt-4",
            },
            buttonsStyling: false,
            didOpen: () => {
                const fileInput = document.getElementById("swal-input-file");
                const fileNameDisplay = document.getElementById("file-name");

                fileInput.addEventListener("change", (e) => {
                    if (e.target.files.length > 0) {
                        fileNameDisplay.textContent = `Geselecteerd: ${e.target.files[0].name}`;
                    } else {
                        fileNameDisplay.textContent = "";
                    }
                });
            },
            preConfirm: () => {
                const titleInput = document.getElementById("swal-input-title").value;
                const fileInput = document.getElementById("swal-input-file").files[0];

                if (!titleInput || !fileInput) {
                    Swal.showValidationMessage("Vul alle velden in");
                    return false;
                }

                return { title: titleInput, file: fileInput };
            },
        }).then((result) => {
            if (result.isConfirmed && result.value) {
                handlePdfUpload(result.value.title, result.value.file);
                Swal.fire({
                    title: "Toegevoegd!",
                    text: "De bijlage is succesvol toegevoegd.",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        });
    };

    return (
        <div className="w-full">
            <div className="flex w-full gap-2">
                <button
                    className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md transition-colors duration-200 flex items-center"
                    onClick={openBijlageModal}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                    Voeg extra bijlage toe
                </button>

                <div className="flex-grow">
                    <Select
                        styles={{
                            control: (base) => ({
                                ...base,
                                minHeight: "42px",
                            }),
                        }}
                        options={extraBijlagen}
                        value={selectedExtraBijlagen}
                        isMulti
                        isClearable={true}
                        closeMenuOnSelect={false}
                        placeholder={extraBijlagen.length > 0 ? "Selecteer bijlagen" : "Geen bijlagen beschikbaar"}
                        noOptionsMessage={() => "Geen bijlagen beschikbaar"}
                        onChange={(selected) => {
                            setSelectedExtraBijlagen(selected || []);
                        }}
                        className="w-full"
                    />
                </div>
            </div>

            {selectedExtraBijlagen.length > 0 && (
                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        {selectedExtraBijlagen.length} bijlage(n) geselecteerd
                    </p>
                </div>
            )}
        </div>
    );
};

export default ExtraBijlagen;
