export const GEBREK_COLORS = {
  Rood: "red",
  Oranje: "orange",
  Geel: "#FFC72C",
  Blauw: "blue",
  Paars: "purple",
  "Geen kleur": "black",
};

export const GEBREK_CODES = {
  Rood: "1",
  Oranje: "2",
  Geel: "3",
  Blauw: "4",
  Paars: "5",
  "Geen kleur": "6",
};

export const PRIORITEIT_OPTIONS = ["Advies", "Cruciaal"];

export const KLEUR_OPTIONS = [
  "",
  ...Object.keys(GEBREK_COLORS).filter((k) => k !== "Geen kleur"),
];
