import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import {
  insert_aansluitgegeven,
  delete_aansluitgegeven,
} from "../../functions/api";

import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";

function InstallatieForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const initialAansluitgegevenState = {
    aansluitgegevenid: location.state?.aansluitgegeven?.aansluitgegevenid ?? 0,
    omschrijving: "",
    toelichting: "",
  };

  const [aansluitgegeven, setAansluitgegeven] = useState(
    location.state?.aansluitgegeven ?? initialAansluitgegevenState
  );
  const [isEditing, setIsEditing] = useState(
    !!location.state?.aansluitgegeven?.aansluitgegevenid || !!id
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.state?.aansluitgegeven) {
      setAansluitgegeven(location.state.aansluitgegeven);
      setIsEditing(true);
    } else if (id) {
      setIsEditing(true);
      console.warn(
        "Editing mode detected from URL, data fetching placeholder."
      );
      if (!location.state?.aansluitgegeven) {
        setAansluitgegeven((prev) => ({
          ...initialAansluitgegevenState,
          aansluitgegevenid: parseInt(id, 10),
        }));
      }
    } else {
      setAansluitgegeven(initialAansluitgegevenState);
      setIsEditing(false);
    }
  }, [location.state, id, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAansluitgegeven((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const saveData = async () => {
    if (
      !aansluitgegeven.omschrijving.trim() ||
      !aansluitgegeven.toelichting.trim()
    ) {
      Swal.fire(
        "Ongeldige invoer",
        "Omschrijving en Waarde mogen niet leeg zijn.",
        "warning"
      );
      return;
    }
    setLoading(true);
    try {
      const response = await insert_aansluitgegeven(aansluitgegeven);

      if (
        response &&
        (response.ok ||
          response.status < 300 ||
          response.result ||
          response.insertId ||
          response.affectedRows > 0 ||
          response.message === "success")
      ) {
        Swal.fire({
          title: "Succes!",
          text: "Gegeven succesvol opgeslagen!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        navigate("/administration/installatie");
      } else {
        throw new Error(response?.message || "Opslaan mislukt");
      }
    } catch (error) {
      console.error("Error saving aansluitgegeven:", error);
      Swal.fire({
        title: "Fout!",
        text: `Opslaan van gegeven mislukt. ${error.message || ""}`,
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    const idToDelete = aansluitgegeven.aansluitgegevenid || parseInt(id, 10);
    if (!idToDelete) return;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2",
        cancelButton: "btn btn-danger mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Weet je zeker dat je dit gegeven wil verwijderen?",
        text: "Dit kan niet ongedaan worden gemaakt.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ja, Verwijder!",
        cancelButtonText: "Nee, Annuleer!",
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          try {
            const response = await delete_aansluitgegeven(idToDelete);

            if (response && response.ok) {
              Swal.fire({
                title: "Verwijderd!",
                text: "Gegeven succesvol verwijderd.",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
              navigate("/administration/installatie");
            } else {
              throw new Error(response?.message || "Verwijderen mislukt");
            }
          } catch (error) {
            console.error("Error deleting aansluitgegeven:", error);
            Swal.fire({
              title: "Fout!",
              text: `Verwijderen van gegeven mislukt. ${error.message || ""}`,
              icon: "error",
            });
          } finally {
            setLoading(false);
          }
        }
      });
  };

  const labelStyle = "block text-sm font-medium text-gray-700 mb-1";
  const inputStyle =
    "block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-gray-100";
  const formGroupStyle = "mb-4";

  return (
    <div className="select-report-container-main bg-gray-100 min-h-screen">
      <Nav
        page={isEditing ? "Gegeven Installatie Bewerken" : "Gegeven Toevoegen"}
      />
      <div className="page-wrap-row flex">
        <Menu className="menu-container" />
        <div className="ml-[37px] md:ml-[163px] lg:ml-[189px] mt-[62px] w-full p-6 text-black">
          <h1 className="text-2xl font-semibold mb-4">
            {isEditing ? "Gegevens Installatie" : "Nieuw Gegeven Installatie"}
          </h1>
          <hr className="hr2 mb-6" />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
            <div className={formGroupStyle}>
              <label htmlFor="omschrijving" className={labelStyle}>
                Omschrijving:
              </label>
              <input
                type="text"
                id="omschrijving"
                name="omschrijving"
                value={aansluitgegeven.omschrijving}
                onChange={handleInputChange}
                className={inputStyle}
                disabled={loading}
                required
              />
            </div>

            <div className={formGroupStyle}>
              <label htmlFor="toelichting" className={labelStyle}>
                Waarde:
              </label>
              <input
                type="text"
                id="toelichting"
                name="toelichting"
                value={aansluitgegeven.toelichting}
                onChange={handleInputChange}
                className={inputStyle}
                disabled={loading}
                required
              />
            </div>
          </div>

          <hr className="hr2 mt-6 mb-4" />
          <div className="flex gap-4 justify-start items-center">
            <button
              type="button"
              onClick={saveData}
              disabled={
                loading ||
                !aansluitgegeven.omschrijving?.trim() ||
                !aansluitgegeven.toelichting?.trim()
              }
              className="px-4 py-2 border rounded-md shadow-sm bg-green-500 text-white hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading
                ? "Opslaan..."
                : isEditing
                ? "Wijzigingen Opslaan"
                : "Gegeven Opslaan"}
            </button>
            <button
              type="button"
              onClick={() => navigate("/administration/installatie")}
              disabled={loading}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              Terug
            </button>
            {isEditing && (
              <button
                type="button"
                onClick={handleDelete}
                disabled={loading}
                className="px-4 py-2 border rounded-md shadow-sm bg-red-500 text-white hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed ml-auto"
              >
                Verwijderen
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstallatieForm;
