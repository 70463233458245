// src/components/common/Icons.js
import React from "react";

export const ExpandIcon = () => <span>▼</span>;
export const CollapseIcon = () => <span>▲</span>;

export const DeleteIcon = ({
  className = "h-5 w-5 text-red-500 hover:text-red-700",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
      clipRule="evenodd"
    />
  </svg>
);

export const AddIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 mr-1 inline"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
      clipRule="evenodd"
    />
  </svg>
);

export const CopyLocationsToNewIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 text-blue-500 hover:text-blue-700"
    viewBox="0 0 25 30"
    width="20"
    height="20"
    fill="currentColor"
  >
    <g>
      <path d="M15.705 18.839C13.529 15.547 13.125 15.21 13.125 14C13.125 12.343 14.412 11 16 11C17.588 11 18.875 12.343 18.875 14C18.875 15.21 18.471 15.547 16.295 18.839C16.153 19.054 15.847 19.054 15.705 18.839ZM16 15.25C16.662 15.25 17.198 14.69 17.198 14C17.198 13.31 16.662 12.75 16 12.75C15.338 12.75 14.802 13.31 14.802 14C14.802 14.69 15.338 15.25 16 15.25Z" />
      <path d="M24.215 3.864L21.32 0.824C20.818 0.296 20.137 0 19.426 0L9.821 0C8.342 0 7.143 1.259 7.143 2.813L7.143 5.625L2.679 5.625C1.199 5.625 0 6.884 0 8.438L0 27.188C0 28.741 1.199 30 2.679 30L15.179 30C16.658 30 17.857 28.741 17.857 27.188L17.857 24.375L22.321 24.375C23.801 24.375 25 23.116 25 21.563L25 5.852C25 5.107 24.718 4.391 24.215 3.864L24.215 3.864ZM14.844 27.188L3.013 27.188C2.828 27.188 2.679 27.03 2.679 26.836L2.679 8.789C2.679 8.595 2.828 8.438 3.013 8.438L7.143 8.438L7.143 21.563C7.143 23.116 8.342 24.375 9.821 24.375L15.179 24.375L15.179 26.836C15.179 27.03 15.029 27.188 14.844 27.188ZM21.987 21.563L10.156 21.563C9.971 21.563 9.821 21.405 9.821 21.211L9.821 3.164C9.821 2.97 9.971 2.813 10.156 2.813L16.071 2.813L16.071 7.969C16.071 8.745 16.671 9.375 17.411 9.375L22.321 9.375L22.321 21.211C22.321 21.405 22.172 21.563 21.987 21.563ZM22.321 6.563L18.75 6.563L18.75 2.813L19.288 2.813C19.376 2.813 19.461 2.85 19.524 2.915L22.223 5.75C22.286 5.815 22.321 5.905 22.321 5.998L22.321 6.563Z" />
    </g>
  </svg>
);

export const CopyEntireGebrekIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 text-green-500 hover:text-green-700"
    viewBox="0 0 25 30"
    width="20"
    height="20"
    fill="currentColor"
  >
    <g>
      <path d="M24.215 3.864L21.32 0.824C20.818 0.296 20.137 0 19.426 0L9.821 0C8.342 0 7.143 1.259 7.143 2.813L7.143 5.625L2.679 5.625C1.199 5.625 0 6.884 0 8.438L0 27.188C0 28.741 1.199 30 2.679 30L15.179 30C16.658 30 17.857 28.741 17.857 27.188L17.857 24.375L22.321 24.375C23.801 24.375 25 23.116 25 21.563L25 5.852C25 5.107 24.718 4.391 24.215 3.864L24.215 3.864ZM14.844 27.188L3.013 27.188C2.828 27.188 2.679 27.03 2.679 26.836L2.679 8.789C2.679 8.595 2.828 8.438 3.013 8.438L7.143 8.438L7.143 21.563C7.143 23.116 8.342 24.375 9.821 24.375L15.179 24.375L15.179 26.836C15.179 27.03 15.029 27.188 14.844 27.188ZM21.987 21.563L10.156 21.563C9.971 21.563 9.821 21.405 9.821 21.211L9.821 3.164C9.821 2.97 9.971 2.813 10.156 2.813L16.071 2.813L16.071 7.969C16.071 8.745 16.671 9.375 17.411 9.375L22.321 9.375L22.321 21.211C22.321 21.405 22.172 21.563 21.987 21.563ZM22.321 6.563L18.75 6.563L18.75 2.813L19.288 2.813C19.376 2.813 19.461 2.85 19.524 2.915L22.223 5.75C22.286 5.815 22.321 5.905 22.321 5.998L22.321 6.563Z" />
    </g>
  </svg>
);

export const PasteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 mr-1 inline"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
    <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
  </svg>
);
