import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Nav from "../navigation/Nav";
import Menu from "../navigation/Menu";
import "moment/locale/nl";
import { AiOutlineLeft } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import "../../scss/customers/create_customer.scss";
import "../../scss/customers/customer.scss";
import _ from "lodash";
import Swal from "sweetalert2"; // Import SweetAlert

// API functions
import {
  create_customer,
  upload_image,
  delete_customer_object,
} from "../../functions/api.js";

function EditCustomer() {
  const navigate = useNavigate();
  const location = useLocation();

  // Initial object structure
  const initialObjectTemplate = {
    naam: "",
    nummer: "",
    telefoonnummer: "",
    bouwjaar: "",
    locatie: {
      adres: "",
      huisnummer: "",
      postcode: "",
      plaats: "",
    },
    contactpersoon: {
      naam: "",
      telefoon: "",
      email: "",
    },
    media: {
      mediaid: "",
      naam: "",
      url: "",
      website: "",
    },
  };

  // State for customer data
  const [userInput, setUserInput] = useState({
    id: "",
    active: "",
    telefoon: "",
    email: "",
    website: "",
    klantnaam: "",
    bedrijfsnaam: "",
    adres: "",
    huisnummer: "",
    postcode: "",
    plaats: "",
    locatieid: "",
  });

  // State for objects array
  const [objectenArr, setObjectenArr] = useState([]);

  // Load customer data from location state
  useEffect(() => {
    if (location.state && location.state.customer) {
      const { customer } = location.state;
      setObjectenArr(customer.objecten || []);

      // Populate userInput from customer data
      for (let key in customer) {
        if (_.isObject(customer[key])) {
          for (let sKey in customer[key]) {
            setUserInput((prev) => ({ ...prev, [sKey]: customer[key][sKey] }));
          }
        } else {
          setUserInput((prev) => ({ ...prev, [key]: customer[key] }));
        }
      }
    }
  }, [location]);

  // Add a new object to the array
  const addObject = () => {
    const newObject = {
      ...initialObjectTemplate,
      klantid: userInput.id,
      locatie: {
        ...initialObjectTemplate.locatie,
        adres: userInput.adres,
        huisnummer: userInput.huisnummer,
        postcode: userInput.postcode,
        plaats: userInput.plaats,
      },
      contactpersoon: {
        ...initialObjectTemplate.contactpersoon,
        naam: userInput.klantnaam,
        telefoon: userInput.telefoon,
        email: userInput.email,
      },
    };

    setObjectenArr((prev) => [...prev, newObject]);
  };

  // Remove an object from the array with confirmation
  const removeObject = async (index) => {
    Swal.fire({
      title: "Weet je het zeker?",
      text: "Wil je dit object verwijderen?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ja, verwijder het!",
      cancelButtonText: "Annuleren",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await delete_customer_object(
            objectenArr[index]?.objectid
          );
          if (response && response.ok) {
            const updatedObjects = [...objectenArr];
            updatedObjects.splice(index, 1);
            setObjectenArr(updatedObjects);

            Swal.fire(
              "Verwijderd!",
              "Het object is succesvol verwijderd.",
              "success"
            );
          } else {
            Swal.fire(
              "Fout!",
              "Er is een fout opgetreden tijdens het verwijderen van het object.",
              "error"
            );
          }
        } catch (error) {
          console.error("Error removing object:", error);
          Swal.fire(
            "Fout!",
            "Er is een fout opgetreden tijdens het verwijderen van het object.",
            "error"
          );
        }
      }
    });
  };

  // Remove image from an object
  const removeImage = (index) => {
    const updatedObjects = [...objectenArr];
    updatedObjects[index] = {
      ...updatedObjects[index],
      media: {
        mediaid: "",
        naam: "",
        url: "",
        website: "",
      },
    };
    setObjectenArr(updatedObjects);
  };

  // Update userInput state
  const handleUserInputChange = (field, value) => {
    setUserInput((prev) => ({ ...prev, [field]: value }));
  };

  // Update object data
  const handleObjectChange = (index, field, value, section = null) => {
    const updatedObjects = [...objectenArr];

    if (section) {
      updatedObjects[index][section] = {
        ...updatedObjects[index][section],
        [field]: value,
      };
    } else {
      updatedObjects[index][field] = value;
    }

    setObjectenArr(updatedObjects);
  };

  // Handle image upload
  const handleImageUpload = async (event, index) => {
    const img = event.target.files[0];

    if (!img) return;

    try {
      const data = await upload_image(img);

      if (data && data.response) {
        const updatedObjects = [...objectenArr];
        updatedObjects[index].media = {
          ...updatedObjects[index].media,
          url: data.response,
          naam: img.name,
        };
        setObjectenArr(updatedObjects);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // Submit the form
  const handleSubmit = async () => {
    try {
      const customerData = {
        id: userInput.id,
        active: userInput.active,
        telefoon: userInput.telefoon,
        email: userInput.email || "",
        website: userInput.website,
        klantnaam: userInput.klantnaam,
        bedrijfsnaam: userInput.bedrijfsnaam,
        locatie: {
          locatieid: userInput.locatieid,
          locatienaam: "",
          adres: userInput.adres,
          huisnummer: userInput.huisnummer,
          postcode: userInput.postcode,
          plaats: userInput.plaats,
        },
        objecten: objectenArr,
      };

      await create_customer(customerData);
      navigate("/customers");

      Swal.fire(
        "Succes!",
        "Klantgegevens zijn succesvol bijgewerkt.",
        "success"
      );
    } catch (error) {
      console.error("Error submitting customer data:", error);
      Swal.fire(
        "Fout!",
        "Er is een fout opgetreden bij het bijwerken van de klantgegevens.",
        "error"
      );
    }
  };

  return (
    <div className="select-customer-container-main">
      <Nav page="Rapportages" />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="create-customer">
          <Link
            className="fixed bottom-0 left-0 ml-4 mb-4"
            to="/customers"
            style={{
              textDecoration: "none",
              color: "#757575",
              width: "85px",
            }}
          >
            <p className="text-base flex flex-row">
              <AiOutlineLeft className="mt-1" />
              terug
            </p>
          </Link>
          <h1 className="customer-header mt-12 md:mt-10 lg:mt-8">Klant</h1>
          <hr className="hr2" />

          {/* Customer Information Section */}
          <div>
            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="label-customer">Naam</label>
                <div className="input-color">
                  <input
                    type="text"
                    value={userInput.klantnaam}
                    onChange={(e) =>
                      handleUserInputChange("klantnaam", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label className="label-customer">Bedrijfsnaam</label>
                <div className="input-color">
                  <input
                    type="text"
                    value={userInput.bedrijfsnaam}
                    onChange={(e) =>
                      handleUserInputChange("bedrijfsnaam", e.target.value)
                    }
                    autoComplete="on"
                  />
                </div>
              </div>
            </div>

            <div className="customer-input-section">
              <label className="label-customer">Adres (straat)</label>
              <div className="input-color">
                <input
                  type="text"
                  value={userInput.adres}
                  onChange={(e) =>
                    handleUserInputChange("adres", e.target.value)
                  }
                  autoComplete="on"
                />
              </div>
            </div>

            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="label-customer">Postcode</label>
                <div className="input-color">
                  <input
                    type="text"
                    value={userInput.postcode}
                    onChange={(e) =>
                      handleUserInputChange("postcode", e.target.value)
                    }
                    autoComplete="on"
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label className="label-customer">Huisnummer</label>
                <div className="input-color">
                  <input
                    type="text"
                    value={userInput.huisnummer}
                    onChange={(e) =>
                      handleUserInputChange("huisnummer", e.target.value)
                    }
                    autoComplete="on"
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label className="label-customer">Plaats</label>
                <div className="input-color">
                  <input
                    type="text"
                    value={userInput.plaats}
                    onChange={(e) =>
                      handleUserInputChange("plaats", e.target.value)
                    }
                    autoComplete="on"
                  />
                </div>
              </div>
            </div>

            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="label-customer">Telefoon</label>
                <div className="input-color">
                  <input
                    type="text"
                    value={userInput.telefoon}
                    onChange={(e) =>
                      handleUserInputChange("telefoon", e.target.value)
                    }
                    autoComplete="on"
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label className="label-customer">Email</label>
                <div className="input-color">
                  <input
                    type="text"
                    value={userInput.email}
                    onChange={(e) =>
                      handleUserInputChange("email", e.target.value)
                    }
                    autoComplete="on"
                  />
                </div>
              </div>
            </div>

            <div className="customer-input-section">
              <label className="label-customer">Website</label>
              <div className="input-color">
                <input
                  type="text"
                  value={userInput.website}
                  onChange={(e) =>
                    handleUserInputChange("website", e.target.value)
                  }
                  autoComplete="on"
                />
              </div>
            </div>

            {/* Add New Object Button */}
            <div className="object-wrapper">
              <div
                className="new-object"
                style={{ cursor: "pointer" }}
                onClick={addObject}
              >
                <BsPlusLg size={25} />
                <div className="new-object-text">
                  <h2>Nieuw object</h2>
                </div>
              </div>
            </div>
            <hr className="hr2" />

            {/* Objects Section */}
            {objectenArr.length > 0 && (
              <div>
                <h1 className="customer-header">New Object</h1>
                {objectenArr.map((object, index) => (
                  <div key={index}>
                    <div className="remove-customer">
                      <button
                        className="submit-customer"
                        onClick={() => removeObject(index)}
                      >
                        Verwijder
                      </button>
                    </div>

                    {/* Object Photo */}
                    <div className="input-section-row">
                      <div className="input-wrapper">
                        <label className="label-customer">
                          Object Foto (JPEG,JPG,PNG)
                        </label>
                        <div className="input-color">
                          <input
                            type="file"
                            accept=".jpeg, .png, .jpg"
                            onChange={(event) =>
                              handleImageUpload(event, index)
                            }
                            aria-label="File browser example"
                          />
                          <span className="file-custom" />
                        </div>
                      </div>
                    </div>

                    {/* Display Image if available */}
                    {object.media.url && (
                      <>
                        <div className="input-section-row">
                          <div className="input-wrapper-image">
                            <img
                              src={`https://ezinspect-api.jitso-dev.nl/${object.media.url}`}
                              alt="Object"
                            />
                          </div>
                        </div>
                        <div className="input-section-row">
                          <div className="input-wrapper">
                            <button
                              className="submit-customer"
                              onClick={() => removeImage(index)}
                            >
                              Verwijder
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    {/* Object Details */}
                    <div className="input-section-row">
                      <div className="input-wrapper">
                        <label className="label-customer">Object naam</label>
                        <div className="input-color">
                          <input
                            type="text"
                            value={object.naam}
                            onChange={(e) =>
                              handleObjectChange(index, "naam", e.target.value)
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label className="label-customer">Object nummer</label>
                        <div className="input-color">
                          <input
                            type="number"
                            value={object.nummer}
                            onChange={(e) =>
                              handleObjectChange(
                                index,
                                "nummer",
                                e.target.value
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Object Address */}
                    <div className="customer-input-section">
                      <label className="label-customer">Adres</label>
                      <div className="input-color">
                        <input
                          type="text"
                          value={object.locatie.adres}
                          onChange={(e) =>
                            handleObjectChange(
                              index,
                              "adres",
                              e.target.value,
                              "locatie"
                            )
                          }
                          autoComplete="on"
                        />
                      </div>
                    </div>

                    <div className="input-section-row">
                      <div className="input-wrapper">
                        <label className="label-customer">Huisnummer</label>
                        <div className="input-color">
                          <input
                            type="text"
                            value={object.locatie.huisnummer}
                            onChange={(e) =>
                              handleObjectChange(
                                index,
                                "huisnummer",
                                e.target.value,
                                "locatie"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label className="label-customer">Postcode</label>
                        <div className="input-color">
                          <input
                            type="text"
                            value={object.locatie.postcode}
                            onChange={(e) =>
                              handleObjectChange(
                                index,
                                "postcode",
                                e.target.value,
                                "locatie"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label className="label-customer">
                          Vestigingsplaats
                        </label>
                        <div className="input-color">
                          <input
                            type="text"
                            value={object.locatie.plaats}
                            onChange={(e) =>
                              handleObjectChange(
                                index,
                                "plaats",
                                e.target.value,
                                "locatie"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="input-section-row">
                      <div className="input-wrapper">
                        <label className="label-customer">Telefoon</label>
                        <div className="input-color">
                          <input
                            type="text"
                            value={object.telefoonnummer}
                            onChange={(e) =>
                              handleObjectChange(
                                index,
                                "telefoonnummer",
                                e.target.value
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label className="label-customer">Bouwjaar</label>
                        <div className="input-color">
                          <input
                            type="text"
                            value={object.bouwjaar}
                            onChange={(e) =>
                              handleObjectChange(
                                index,
                                "bouwjaar",
                                e.target.value
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Contact Information */}
                    <h1 className="customer-header">Contactgegevens</h1>
                    <div className="input-section-row">
                      <div className="input-wrapper">
                        <label className="label-customer">Naam</label>
                        <div className="input-color">
                          <input
                            type="text"
                            value={object.contactpersoon.naam}
                            onChange={(e) =>
                              handleObjectChange(
                                index,
                                "naam",
                                e.target.value,
                                "contactpersoon"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label className="label-customer">Telefoon</label>
                        <div className="input-color">
                          <input
                            type="text"
                            value={object.contactpersoon.telefoon}
                            onChange={(e) =>
                              handleObjectChange(
                                index,
                                "telefoon",
                                e.target.value,
                                "contactpersoon"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label className="label-customer">Email</label>
                        <div className="input-color">
                          <input
                            type="text"
                            value={object.contactpersoon.email}
                            onChange={(e) =>
                              handleObjectChange(
                                index,
                                "email",
                                e.target.value,
                                "contactpersoon"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="hr2" />
                  </div>
                ))}
              </div>
            )}

            {/* Submit Button */}
            <div style={{ height: "100px" }}>
              <br />
              <button className="submit-customer" onClick={handleSubmit}>
                Bewerken
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCustomer;
