import React from "react";
import { Link } from "react-router-dom";

import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";
import {
  FaArrowAltCircleRight,
  FaArrowRight,
  FaFontAwesome,
} from "react-icons/fa";

function Administration() {
  const adminSections = [
    {
      title: "Tekstenbieb",
      description: "Beheer tekstenbieb items.",
      link: "/administration/tekstenbieb",
    },
    {
      title: "Meetinstrumenten",
      description: "Beheer meetinstrumenten.",
      link: "/administration/meetinstrumenten",
    },
    {
      title: "Soorten functies van object",
      description: "Beheer soorten functies van object.",
      link: "/administration/functies",
    },
    {
      title: "Gegevens installatie",
      description: "Beheer gegevens installatie.",
      link: "/administration/installatie",
    },
    {
      title: "Normen",
      description: "Beheer normen.",
      link: "/administration/normen",
    },
  ];

  return (
    <div className="select-report-container-main">
      <Nav page="Administratie" />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="select-report-container p-4">
          <h1 className="text-2xl font-semibold mb-4 ml-10 mt-10">
            Administratie
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 sm:px-6 lg:px-8 lg:ml-4">
            {adminSections.map((section, index) => (
              <Link
                to={section.link}
                key={index}
                className="block text-decoration-none rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-200 bg-white"
              >
                <div className="p-4">
                  <h5 className="text-xl font-semibold text-gray-900 mb-2">
                    {section.title}
                  </h5>
                  <p className="text-gray-700 text-sm">{section.description}</p>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right">
                  <div className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full justify-between text-xs font-medium bg-blue-100 text-blue-800">
                    <span>Bekijk</span>
                    <FaArrowRight className="mt-[0.5px]" />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Administration;
