import { MediaItem } from "./MediaItem";

import {
  GEBREK_COLORS,
  PRIORITEIT_OPTIONS,
  KLEUR_OPTIONS,
} from "../../../utils/constants";
import {
  getEffectiveGebrekColor,
  formatDateTimeForSaving,
} from "../../../utils/helpers";
import {
  AddIcon,
  CollapseIcon,
  CopyEntireGebrekIcon,
  CopyLocationsToNewIcon,
  DeleteIcon,
  ExpandIcon,
  PasteIcon,
} from "../../common/icons";
import {
  delete_media,
  report_situatie,
  upload_image,
} from "../../../functions/api";
import Swal from "sweetalert2";

async function upload_media_api(file, referenceId, type = "gebrek") {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  const tempMediaId = `temp-media-${type}-${Date.now()}`;
  const simulatedUrl = URL.createObjectURL(file);
  return {
    mediaid: tempMediaId,
    url: simulatedUrl,
    naam: file.name,
    type: file.type,
  };
}

const GebrekItem = ({
  gebrek,
  index,
  isCollapsed,
  uploadError,
  gsUploadErrors = {},
  newLocationInput,
  copiedLocationsData,
  gebrekRef,
  onToggleCollapse,
  onDelete,
  onChange,
  onLocationInputChange,
  onAddLocation,
  onCreateNewWithLocations,
  onCopyLocations,
  onPasteLocations,
  onUpdateGebrek,
  onSetUploadError,
  onSetGsUploadError,
  onCopyEntireGebrek,
}) => {
  const title = gebrek.gebrekcode?.naam
    ? `Gebrek ${gebrek.gebrekid} (${gebrek.gebrekcode.naam})`
    : `Nieuw Gebrek ${
        gebrek.gebrekid.startsWith("temp-")
          ? "(Nog niet opgeslagen)"
          : gebrek.gebrekid
      }`;
  const effectiveColor = getEffectiveGebrekColor(gebrek);

  const handleMediaUploadInternal = async (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    onSetUploadError(gebrek.gebrekid, null);

    let updatedMedia = [...(gebrek.media || [])];
    let errorOccurred = false;

    for (const file of files) {
      try {
        const response = await upload_image(file);

        if (response && response.response) {
          const mediaItem = response.response;
          updatedMedia.push({
            mediaid: mediaItem.mediaid,
            url: mediaItem.url,
            naam: mediaItem.naam,
            type: file.type,
          });
        } else {
          throw new Error("Upload response did not contain valid media data.");
        }
      } catch (uploadErr) {
        console.error("Upload failed for file:", file.name, uploadErr);
        onSetUploadError(
          gebrek.gebrekid,
          `Uploaden van ${file.name} mislukt: ${uploadErr.message}`
        );
        errorOccurred = true;
      }
    }

    if (!errorOccurred || updatedMedia.length > (gebrek.media?.length || 0)) {
      onUpdateGebrek(index, { ...gebrek, media: updatedMedia });
    }
    event.target.value = null;
  };

  const addGevaarlijkeSituatieInternal = () => {
    const tempId = `temp-gs-${Date.now()}`;
    const now = new Date();
    const formattedDate = formatDateTimeForSaving(now);
    const newSituatie = {
      gevaarlijkesituatieid: tempId,
      datum: formattedDate,
      media: null,
      mediaid: null,
    };
    const updatedSituaties = [
      ...(gebrek.gevaarlijkesituatie || []),
      newSituatie,
    ];
    onUpdateGebrek(index, {
      ...gebrek,
      gevaarlijkesituatie: updatedSituaties,
    });
  };

  const deleteGevaarlijkeSituatieInternal = (situatieIdToDelete) => {
    Swal.fire({
      title: "Weet u het zeker?",
      text: "U staat op het punt deze gevaarlijke situatie melding te verwijderen.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Ja, verwijder!",
      cancelButtonText: "Annuleren",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedSituaties = (gebrek.gevaarlijkesituatie || []).filter(
          (gs) => gs.gevaarlijkesituatieid !== situatieIdToDelete
        );
        onUpdateGebrek(index, {
          ...gebrek,
          gevaarlijkesituatie: updatedSituaties,
        });
        onSetGsUploadError(situatieIdToDelete, null);
        Swal.fire({
          title: "Verwijderd!",
          text: "De melding is verwijderd.",
          icon: "success",
          timer: 1000,
          showCloseButton: true,
        });
      }
    });
  };

  const handleGevaarlijkeSituatieMediaUploadInternal = async (
    situatieIndex,
    situatieId,
    event
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    onSetGsUploadError(situatieId, null);

    try {
      const response = await upload_image(file);

      if (response && response.response) {
        const mediaItem = response.response;
        const updatedSituaties = [...(gebrek.gevaarlijkesituatie || [])];
        if (
          updatedSituaties[situatieIndex] &&
          updatedSituaties[situatieIndex].gevaarlijkesituatieid === situatieId
        ) {
          updatedSituaties[situatieIndex] = {
            ...updatedSituaties[situatieIndex],
            media: mediaItem.url,
            mediaid: mediaItem.mediaid,
          };
          onUpdateGebrek(index, {
            ...gebrek,
            gevaarlijkesituatie: updatedSituaties,
          });
        } else {
          console.error("Mismatch in situatie index/ID during update");
        }
      } else {
        throw new Error("Upload response did not contain valid media data.");
      }
    } catch (uploadErr) {
      console.error("GS Media Upload failed:", uploadErr);
      onSetGsUploadError(situatieId, `Upload mislukt: ${uploadErr.message}`);
    } finally {
      event.target.value = null;
    }
  };

  const handleReportSituatie = async (situatieIndex, situatieId, event) => {
    event.preventDefault();
    const situatie = gebrek.gevaarlijkesituatie?.[situatieIndex];

    if (
      !gebrek?.gebrekid ||
      !situatie ||
      situatie.gevaarlijkesituatieid !== situatieId ||
      !situatie.mediaid
    ) {
      console.error("Missing data required to report situatie:", {
        gebrekId: gebrek?.gebrekid,
        situatie,
        expectedSituatieId: situatieId,
      });
      Swal.fire({
        icon: "error",
        title: "Fout",
        text: "Kan situatie niet melden. Benodigde gegevens ontbreken of er is geen media gekoppeld aan deze situatie.",
      });
      return;
    }

    Swal.fire({
      title: "Weet u het zeker?",
      text: "U staat op het punt deze situatie als gevaarlijk te melden. Deze actie kan niet ongedaan worden gemaakt.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Ja, meld het!",
      cancelButtonText: "Annuleren",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const situatieObject = {
          gebrekid: gebrek.gebrekid,
          signmedia: situatie.mediaid,
        };

        Swal.fire({
          title: "Bezig met melden...",
          text: "Een ogenblik geduld.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        try {
          console.log("Reporting situatie with data:", situatieObject);
          const response = await report_situatie(situatieObject);
          console.log("API Response:", response);

          if (response && response.ok) {
            Swal.fire({
              icon: "success",
              title: "Gemeld!",
              text: "De gevaarlijke situatie is succesvol gemeld.",
              timer: 2000,
              timerProgressBar: true,
            });
            // Update the local state to reflect the change from temp ID
            const updatedSituaties = gebrek.gevaarlijkesituatie.map((sit) =>
              sit.gevaarlijkesituatieid === situatieId
                ? {
                    ...sit,
                    gevaarlijkesituatieid: response.newId || "reported",
                  } // Use actual ID from response if available
                : sit
            );
            onUpdateGebrek(index, {
              ...gebrek,
              gevaarlijkesituatie: updatedSituaties,
            });
          } else {
            throw new Error(
              response?.message || "De API gaf een onverwachte response."
            );
          }
        } catch (error) {
          console.error("Failed to report situatie:", error);
          Swal.fire({
            icon: "error",
            title: "Melden Mislukt",
            text: `Er is een fout opgetreden: ${error.message}`,
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("User cancelled reporting the situation.");
      }
    });
  };

  const deleteMediaItem = (mediaId) => {
    Swal.fire({
      title: "Weet je het zeker?",
      text: "U staat op het punt dit media item te verwijderen.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Ja, verwijder!",
      cancelButtonText: "Annuleren",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await delete_media(mediaId);
          if (response && response.ok) {
            const updatedMedia = (gebrek.media || []).filter(
              (mediaItem) => mediaItem.gebrekmediaid !== mediaId
            );
            onUpdateGebrek(index, { ...gebrek, media: updatedMedia });

            Swal.fire({
              title: "Verwijderd!",
              text: "Het media item is succesvol verwijderd.",
              icon: "success",
              timer: 1000,
              showCloseButton: true,
            });
          } else {
            throw new Error(response?.message || "Kon media niet verwijderen.");
          }
        } catch (error) {
          console.error("Failed to delete media:", error);
          Swal.fire({
            icon: "error",
            title: "Verwijderen Mislukt",
            text: `Er is een fout opgetreden: ${error.message}`,
          });
        }
      }
    });
  };

  const renderMedia = (mediaList) => {
    if (!mediaList || mediaList.length === 0) return null;
    return mediaList.map((item, idx) => (
      <div
        key={item.mediaid || item.url || `media-${gebrek.gebrekid}-${idx}`}
        className="relative"
      >
        <MediaItem mediaItem={item} />
        <button
          onClick={() => deleteMediaItem(item.gebrekmediaid)}
          className="absolute top-1 right-1 p-0.5 rounded hover:bg-red-100 focus:outline-none"
          title="Verwijder Media"
        >
          <DeleteIcon className="h-4 w-4 text-red-500 hover:text-red-700" />
        </button>
      </div>
    ));
  };

  const hasReportedSituation = gebrek.gevaarlijkesituatie?.some(
    (sit) => !sit.gevaarlijkesituatieid.startsWith("temp-")
  );

  return (
    <div
      ref={gebrekRef}
      className="flex flex-col w-full border rounded-md mb-4 shadow-sm bg-white"
    >
      {/* Header */}
      <div
        className={`flex justify-between items-center p-3 cursor-pointer border-b ${
          isCollapsed ? "" : "bg-gray-50"
        }`}
        onClick={() => onToggleCollapse(gebrek.gebrekid)}
      >
        <div className="flex items-center space-x-2 flex-grow mr-2 min-w-0">
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onToggleCollapse(gebrek.gebrekid);
            }}
            className="focus:outline-none flex-shrink-0"
            aria-expanded={!isCollapsed}
            aria-controls={`gebrek-details-${gebrek.gebrekid}`}
          >
            {isCollapsed ? <ExpandIcon /> : <CollapseIcon />}
          </button>
          <p
            className="text-base font-semibold truncate"
            style={{ color: effectiveColor }}
            title={title}
          >
            {title}
          </p>
        </div>
        <div className="flex items-center space-x-2 flex-shrink-0">
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onCopyEntireGebrek(gebrek);
            }}
            className="p-1 rounded hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-300"
            title="Kopieer Gehele Gebrek"
          >
            <CopyEntireGebrekIcon />
          </button>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onCreateNewWithLocations(gebrek);
            }}
            className="p-1 rounded hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-300"
            title="Nieuw Gebrek met Locaties van Dit Gebrek"
          >
            <CopyLocationsToNewIcon />
          </button>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(gebrek.gebrekid);
            }}
            className="p-1 rounded hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-300"
            title="Verwijder Gebrek"
          >
            <DeleteIcon />
          </button>
        </div>
      </div>

      {/* Collapsible Details Section */}
      {!isCollapsed && (
        <div id={`gebrek-details-${gebrek.gebrekid}`} className="p-4 space-y-4">
          {/* Prioriteit & Kleur */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col space-y-1">
              <label
                htmlFor={`prioriteit-${gebrek.gebrekid}`}
                className="text-sm font-semibold text-gray-700"
              >
                Prioriteit:
              </label>
              <select
                id={`prioriteit-${gebrek.gebrekid}`}
                value={gebrek.prioriteit || PRIORITEIT_OPTIONS[0]}
                onChange={(e) => onChange(index, "prioriteit", e.target.value)}
                className="p-3 border rounded-md shadow-sm focus:ring focus:ring-blue-300 bg-white"
              >
                {PRIORITEIT_OPTIONS.map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col space-y-1">
              <label
                htmlFor={`kleur-${gebrek.gebrekid}`}
                className="text-sm font-semibold text-gray-700"
              >
                Kleur (overschrijft code):
              </label>
              <select
                id={`kleur-${gebrek.gebrekid}`}
                value={gebrek.kleur || ""}
                onChange={(e) => onChange(index, "kleur", e.target.value)}
                className="p-3 border rounded-md shadow-sm focus:ring focus:ring-blue-300 bg-white"
                style={{
                  color: gebrek.kleur ? GEBREK_COLORS[gebrek.kleur] : "inherit",
                }}
              >
                {KLEUR_OPTIONS.map((opt) => (
                  <option
                    key={opt}
                    value={opt}
                    style={{ color: opt ? GEBREK_COLORS[opt] : "black" }}
                  >
                    {opt ||
                      (gebrek.gebrekcode?.naam
                        ? `Standaard (${gebrek.gebrekcode.naam})`
                        : "Standaard")}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Omschrijving Input */}
          <div className="flex flex-col space-y-1">
            <label
              htmlFor={`omschrijving-${gebrek.gebrekid}`}
              className="text-sm font-semibold text-gray-700"
            >
              Omschrijving:
            </label>
            <input
              id={`omschrijving-${gebrek.gebrekid}`}
              type="text"
              value={gebrek.omschrijving || ""}
              onChange={(e) => onChange(index, "omschrijving", e.target.value)}
              className="p-3 border rounded-md shadow-sm focus:ring focus:ring-blue-300"
              placeholder="Omschrijving van het gebrek"
            />
          </div>

          {/* Toelichting Input */}
          <div className="flex flex-col space-y-1">
            <label
              htmlFor={`toelichting-${gebrek.gebrekid}`}
              className="text-sm font-semibold text-gray-700"
            >
              Toelichting:
            </label>
            <input
              id={`toelichting-${gebrek.gebrekid}`}
              type="text"
              value={gebrek.toelichting || ""}
              placeholder="Vul hier een toelichting in (optioneel)"
              onChange={(e) => onChange(index, "toelichting", e.target.value)}
              className="p-3 border rounded-md shadow-sm focus:ring focus:ring-blue-300"
            />
          </div>

          {/* --- Locaties Section --- */}
          <div>
            <div className="flex justify-between items-center mb-1">
              <h3 className="text-sm font-semibold text-gray-700">
                Locatie(s):
              </h3>
              <div className="flex items-center space-x-2">
                {copiedLocationsData &&
                  copiedLocationsData.sourceGebrekId !== gebrek.gebrekid && (
                    <button
                      type="button"
                      onClick={() => onPasteLocations(index, gebrek.gebrekid)}
                      className="px-2 py-0.5 bg-purple-100 text-purple-700 text-xs font-semibold rounded shadow-sm hover:bg-purple-200"
                      title={`Plak ${copiedLocationsData.locations.length} locatie(s)`}
                    >
                      <PasteIcon /> Plakken
                    </button>
                  )}
                <button
                  type="button"
                  onClick={() =>
                    onCopyLocations(gebrek.gebrekid, gebrek.locaties)
                  }
                  disabled={!gebrek.locaties || gebrek.locaties.length === 0}
                  className="px-2 py-0.5 bg-blue-100 text-blue-700 text-xs font-semibold rounded shadow-sm hover:bg-blue-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  title="Kopieer locaties om in ander gebrek te plakken"
                >
                  Kopieer
                </button>
              </div>
            </div>
            {/* Display existing locations */}
            <div className="flex flex-row flex-wrap gap-2 mb-2">
              {gebrek.locaties?.length > 0 ? (
                gebrek.locaties.map((locatie) => (
                  <input
                    key={locatie.locatieid}
                    type="text"
                    value={locatie.locatienaam}
                    disabled
                    className="p-2 border rounded-md shadow-sm bg-gray-200 text-sm"
                    title={`ID: ${locatie.locatieid}`}
                  />
                ))
              ) : (
                <p className="text-sm text-gray-500 italic">
                  Geen locaties toegevoegd.
                </p>
              )}
            </div>
            {/* Add new location input */}
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={newLocationInput || ""}
                onChange={(e) =>
                  onLocationInputChange(gebrek.gebrekid, e.target.value)
                }
                placeholder="Nieuwe locatienaam"
                className="p-2 border rounded-md shadow-sm focus:ring focus:ring-blue-300 flex-grow text-sm"
              />
              <button
                type="button"
                onClick={() => onAddLocation(index, gebrek.gebrekid)}
                className="px-3 py-1 bg-blue-500 text-white text-sm font-semibold rounded-md shadow-sm hover:bg-blue-600 whitespace-nowrap"
              >
                <AddIcon /> Toevoegen
              </button>
            </div>
          </div>
          {/* --- End Locaties Section --- */}

          {/* --- Media Section (Gebrek) --- */}
          <div>
            <h3 className="text-sm font-semibold text-gray-700 mb-2">
              Media (Gebrek):
            </h3>
            <div className="flex flex-wrap gap-4 mb-2">
              {gebrek.media?.length > 0 ? (
                renderMedia(gebrek.media)
              ) : (
                <p className="text-sm text-gray-500 italic">
                  Geen media toegevoegd aan gebrek.
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor={`media-upload-${gebrek.gebrekid}`}
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Nieuwe media toevoegen (Gebrek):
              </label>
              <input
                id={`media-upload-${gebrek.gebrekid}`}
                type="file"
                multiple
                accept="image/*,video/*"
                onChange={handleMediaUploadInternal}
                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              />
              {uploadError && (
                <p className="text-xs text-red-600 mt-1">{uploadError}</p>
              )}
            </div>
          </div>
          {/* --- End Media Section (Gebrek) --- */}

          {/* --- Gevaarlijke Situatie Section --- */}
          {!gebrek.gebrekid.startsWith("temp") && (
            <div className="border-t pt-4 mt-4">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-sm font-semibold text-red-800">
                  Gevaarlijke Situatie(s):
                </h3>
                {!hasReportedSituation && (
                  <button
                    type="button"
                    onClick={addGevaarlijkeSituatieInternal}
                    className="px-3 py-1 bg-yellow-500 text-white text-sm font-semibold rounded-md shadow-sm hover:bg-yellow-600"
                  >
                    <AddIcon /> Melden
                  </button>
                )}
                {hasReportedSituation && (
                  <span className="text-sm text-red-700 font-semibold bg-red-100 px-2 py-1 rounded">
                    Situatie Gemeld
                  </span>
                )}
              </div>

              {(!gebrek.gevaarlijkesituatie ||
                gebrek.gevaarlijkesituatie.length === 0) &&
                !hasReportedSituation && (
                  <p className="text-sm text-gray-500 italic">
                    Geen gevaarlijke situaties gemeld.
                  </p>
                )}

              <div className="space-y-3">
                {gebrek.gevaarlijkesituatie?.map((situatie, sitIndex) => {
                  const gsError =
                    gsUploadErrors[situatie.gevaarlijkesituatieid];
                  const isReported =
                    !situatie.gevaarlijkesituatieid.startsWith("temp-");

                  return (
                    <div
                      key={situatie.gevaarlijkesituatieid}
                      className={`p-3 border rounded space-y-2 relative ${
                        isReported
                          ? "bg-red-50 border-red-300"
                          : "bg-yellow-50 border-yellow-300"
                      }`}
                    >
                      {!isReported && (
                        <button
                          type="button"
                          onClick={() =>
                            deleteGevaarlijkeSituatieInternal(
                              situatie.gevaarlijkesituatieid
                            )
                          }
                          className="absolute top-1 right-1 p-0.5 rounded hover:bg-red-100 focus:outline-none"
                          title="Verwijder Gevaarlijke Situatie Melding"
                        >
                          <DeleteIcon className="h-4 w-4 text-red-500 hover:text-red-700" />
                        </button>
                      )}
                      <p
                        className={`text-xs ${
                          isReported ? "text-red-700" : "text-yellow-700"
                        }`}
                      >
                        Datum: {situatie.datum || "Onbekend"}
                        <span className="text-gray-400 ml-2">
                          (ID: {situatie.gevaarlijkesituatieid})
                        </span>
                      </p>
                      <div className="flex items-center gap-4">
                        {situatie.media ? (
                          <div className="flex-shrink-0">
                            <MediaItem
                              mediaItem={{
                                url: situatie.media,
                                naam: `gs-media-${situatie.gevaarlijkesituatieid}`,
                              }}
                            />
                          </div>
                        ) : (
                          <p className="text-xs text-yellow-600 italic flex-shrink-0">
                            Geen media.
                          </p>
                        )}
                        {!isReported && (
                          <div className="flex-grow">
                            <label
                              htmlFor={`gs-media-upload-${situatie.gevaarlijkesituatieid}`}
                              className="sr-only"
                            >
                              Media toevoegen
                            </label>
                            <input
                              id={`gs-media-upload-${situatie.gevaarlijkesituatieid}`}
                              type="file"
                              accept="image/*,video/*"
                              onChange={(e) =>
                                handleGevaarlijkeSituatieMediaUploadInternal(
                                  sitIndex,
                                  situatie.gevaarlijkesituatieid,
                                  e
                                )
                              }
                              className="block w-full text-xs text-gray-500 file:mr-2 file:py-1 file:px-2 file:rounded file:border-0 file:text-xs file:font-semibold file:bg-yellow-100 file:text-yellow-700 hover:file:bg-yellow-200"
                            />
                            {gsError && (
                              <p className="text-xs text-red-600 mt-1">
                                {gsError}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                      {!isReported && situatie.media && (
                        <button
                          onClick={(e) =>
                            handleReportSituatie(
                              sitIndex,
                              situatie.gevaarlijkesituatieid,
                              e
                            )
                          }
                          className="absolute right-2 bottom-2 text-sm font-semibold py-1 px-3 rounded-md border shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-1 text-red-800 bg-red-200 border-red-400 hover:bg-red-300 focus:ring-red-400 cursor-pointer"
                          title="Meld deze situatie als gevaarlijk"
                        >
                          Gevaarlijke situatie melden
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {gebrek.gebrekid.startsWith("temp") && (
            <div className="bg-yellow-100 p-2 rounded-md">
              <small className="text-yellow-800">
                Sla eerst de inspectie op om een gevaarlijke situatie te melden!
              </small>
            </div>
          )}
          {/* --- End Gevaarlijke Situatie Section --- */}
        </div>
      )}
    </div>
  );
};

export default GebrekItem;
