import { format } from "date-fns";
import { GEBREK_COLORS } from "./constants";

export const getEffectiveGebrekColor = (gebrek) => {
  if (gebrek?.kleur && GEBREK_COLORS[gebrek.kleur]) {
    return GEBREK_COLORS[gebrek.kleur];
  }
  if (gebrek?.gebrekcode?.naam && GEBREK_COLORS[gebrek.gebrekcode.naam]) {
    return GEBREK_COLORS[gebrek.gebrekcode.naam];
  }
  return GEBREK_COLORS["Geen kleur"] || "black";
};

export const formatDateForInput = (dateString) => {
  if (!dateString || dateString === "0000-00-00 00:00:00") {
    return null;
  }
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? null : date;
};

export const formatDateTimeForSaving = (date) => {
  if (!date) return null;
  try {
    return format(date, "yyyy-MM-dd HH:mm:ss");
  } catch {
    return null; // Handle invalid date object
  }
};

// Generate a unique temporary ID
export const generateTempId = (prefix) =>
  `${prefix}-${Date.now()}-${Math.random().toString(16).slice(2)}`;
