import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get_inspecties } from "../../functions/api";
import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";
import "../../scss/reports/select-report.scss";
import Moment from "react-moment";

export default function Inspections() {
  const [inspections, setInspections] = useState([]);
  const [filteredInspections, setFilteredInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState({
    field: "datumstart",
    order: "desc",
  });

  const navigate = useNavigate();

  const thStyle =
    "px-4 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-lg font-semibold text-gray-600 cursor-pointer";
  const tdStyle = "px-4 py-3 border-b border-gray-200 text-lg";

  useEffect(() => {
    get_inspecties()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setInspections(data);
        setFilteredInspections(data);
        setLoading(false);
      });
  }, []);

  const handleSearch = (term) => {
    setSearchTerm(term);
    const lowercasedFilter = term.toLowerCase();
    const filteredData = inspections.filter((item) =>
      item.klant.klantnaam.toLowerCase().includes(lowercasedFilter)
    );
    handleSort(sortOrder.field, sortOrder.order, filteredData);
  };

  const handleSort = (field, order, data = filteredInspections) => {
    const sortedData = [...data].sort((a, b) => {
      if (field === "datumstart") {
        const dateA = new Date(a[field]);
        const dateB = new Date(b[field]);
        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else if (field === "klant.klantnaam") {
        const nameA = a.klant.klantnaam.toLowerCase();
        const nameB = b.klant.klantnaam.toLowerCase();
        if (nameA < nameB) return order === "asc" ? -1 : 1;
        if (nameA > nameB) return order === "asc" ? 1 : -1;
        return 0;
      }
    });
    setFilteredInspections(sortedData);
    setSortOrder({ field, order });
  };

  const resetFilters = () => {
    setSearchTerm("");
    setSortOrder({ field: "datumstart", order: "desc" });
    setFilteredInspections(inspections);
  };

  const renderStatus = (statusId) => {
    switch (statusId) {
      case "1":
        return "Actief";
      case "2":
        return "Inactief";
      case "3":
        return "Afgerond";
      case "4":
        return "Niet afgerond";
      default:
        return "Actief";
    }
  };

  const inspectionList = () => {
    if (filteredInspections.length > 0) {
      return filteredInspections.map((inspection) => (
        <tr
          key={inspection.id}
          className="hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
          onClick={() =>
            navigate(`/inspections/edit_inspection/${inspection.id}`)
          }
        >
          <td className={tdStyle}>{inspection.id}</td>
          <td className={tdStyle}>{inspection.klant.klantnaam}</td>
          <td className={tdStyle}>
            <Moment locale="nl" format="DD-MMM YYYY">
              {inspection.datumstart === null ||
              inspection.datumstart === "0000-00-00 00:00:00"
                ? new Date()
                : inspection.datumstart}
            </Moment>
          </td>
          <td className={tdStyle}>
            {renderStatus(inspection.status.statusid)}
          </td>
        </tr>
      ));
    }
    return (
      <tr>
        <td colSpan="4" className="px-4 py-2 text-center">
          Geen inspecties gevonden
        </td>
      </tr>
    );
  };

  return (
    <div className="select-report-container-main">
      <Nav page="Inspecties" />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="select-report-container p-4">
          <h1 className="text-2xl font-semibold mb-4 ml-10 mt-10">
            Inspecties
          </h1>
          <div className="px-10 mb-4 flex justify-between items-center">
            <input
              type="text"
              placeholder="Zoek op naam..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="px-4 py-2 border rounded-md shadow-sm w-1/3"
            />
            <div className="flex space-x-4">
              <button
                onClick={() =>
                  handleSort(
                    "datumstart",
                    sortOrder.order === "asc" ? "desc" : "asc"
                  )
                }
                className="px-4 py-2 border rounded-md shadow-sm"
              >
                Sorteer op Datum{" "}
                {sortOrder.field === "datumstart" &&
                  (sortOrder.order === "asc" ? "▲" : "▼")}
              </button>
              <button
                onClick={() =>
                  handleSort(
                    "klant.klantnaam",
                    sortOrder.order === "asc" ? "desc" : "asc"
                  )
                }
                className="px-4 py-2 border rounded-md shadow-sm"
              >
                Sorteer op Naam{" "}
                {sortOrder.field === "klant.klantnaam" &&
                  (sortOrder.order === "asc" ? "▲" : "▼")}
              </button>
              <button
                onClick={resetFilters}
                className="px-4 py-2 border rounded-md shadow-sm bg-red-500 text-white"
              >
                Reset Filters
              </button>
            </div>
          </div>
          <div className="select-report-container-1">
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className={thStyle}>Id</th>
                    <th className={thStyle}>Naam</th>
                    <th className={thStyle}>Datum</th>
                    <th className={thStyle}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loading === true ? (
                    <tr>
                      <td colSpan={4} className="px-4 py-2 text-center">
                        Laden...
                      </td>
                    </tr>
                  ) : (
                    inspectionList()
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
