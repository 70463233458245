// src/hooks/useInspection.js
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { get_one_inspection, update_inspectie } from "../functions/api";
import { PRIORITEIT_OPTIONS, GEBREK_CODES } from "../utils/constants";
import { generateTempId } from "../utils/helpers";

export const useInspection = (inspectionId) => {
  const navigate = useNavigate();

  const [inspection, setInspection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [uploadErrors, setUploadErrors] = useState({});
  const [gsUploadErrors, setGsUploadErrors] = useState({});
  const [collapsedGebreken, setCollapsedGebreken] = useState({});
  const gebrekRefs = useRef({});
  const [newLocationInputs, setNewLocationInputs] = useState({});
  const [copiedLocationsData, setCopiedLocationsData] = useState(null);

  // --- Fetch inspection data ---
  const fetchInspection = useCallback(async () => {
    if (!inspectionId) {
      setFetchError("Geen inspectie ID gevonden.");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setError(null);
    setFetchError(null);
    setUploadErrors({});
    setGsUploadErrors({});
    setCopiedLocationsData(null);
    try {
      const response = await get_one_inspection(inspectionId);
      if (!response.ok) {
        throw new Error(
          `Failed to fetch inspection data (status: ${response.status})`
        );
      }
      const data = await response.json();
      if (data) {
        setInspection(data);
        const initialCollapseState = {};
        gebrekRefs.current = {};
        data.gebreken?.forEach((g) => {
          initialCollapseState[g.gebrekid] = true;
          gebrekRefs.current[g.gebrekid] = React.createRef();
        });
        setCollapsedGebreken(initialCollapseState);
        setNewLocationInputs({});
      } else {
        setFetchError("Inspectie niet gevonden.");
        setInspection(null);
      }
    } catch (err) {
      console.error("Error fetching inspection:", err);
      setFetchError(
        err.message ||
          "Er is een fout opgetreden bij het ophalen van de inspectie."
      );
      setInspection(null);
    } finally {
      setIsLoading(false);
    }
  }, [inspectionId]);

  useEffect(() => {
    fetchInspection();
  }, [fetchInspection]);

  // --- Handlers for modifying inspection state ---

  const handleUpdateGebrek = useCallback((index, updatedGebrekData) => {
    setInspection((prev) => {
      if (!prev || !prev.gebreken || !prev.gebreken[index]) return prev;
      const updatedGebreken = [...prev.gebreken];
      updatedGebreken[index] = updatedGebrekData;
      return { ...prev, gebreken: updatedGebreken };
    });
  }, []);

  const handleGebrekChange = useCallback((index, field, value) => {
    setInspection((prev) => {
      if (!prev || !prev.gebreken || !prev.gebreken[index]) return prev;
      const updatedGebreken = [...prev.gebreken];
      const finalValue = field === "kleur" && value === "" ? null : value;
      updatedGebreken[index] = {
        ...updatedGebreken[index],
        [field]: finalValue,
      };

      const currentGebrek = updatedGebreken[index];
      const updates = { [field]: finalValue };

      if (field === "kleur") {
        const selectedKleur = finalValue;
        const gebrekcodeid =
          selectedKleur && GEBREK_CODES.hasOwnProperty(selectedKleur)
            ? GEBREK_CODES[selectedKleur]
            : "1";
        updates.gebrekcode = {
          gebrekcodeid: gebrekcodeid,
          naam: selectedKleur,
        };
      }

      updatedGebreken[index] = { ...currentGebrek, ...updates };
      return { ...prev, gebreken: updatedGebreken };
    });
  }, []);

  // --- Collapse/Expand Logic ---
  const toggleGebrekCollapse = useCallback((gebrekId) => {
    setCollapsedGebreken((prev) => ({
      ...prev,
      [gebrekId]: !prev[gebrekId],
    }));
  }, []);

  const collapseAllGebreken = useCallback(() => {
    if (!inspection?.gebreken) return;
    const allCollapsed = {};
    inspection.gebreken.forEach((g) => {
      allCollapsed[g.gebrekid] = true;
    });
    setCollapsedGebreken(allCollapsed);
  }, [inspection?.gebreken]);

  const expandAllGebreken = useCallback(() => {
    if (!inspection?.gebreken) return;
    const allExpanded = {};
    inspection.gebreken.forEach((g) => {
      allExpanded[g.gebrekid] = false;
    });
    setCollapsedGebreken(allExpanded);
  }, [inspection?.gebreken]);

  // --- Gebrek Add/Delete/Copy Logic ---
  const addGebrek = useCallback((initialLocations = null) => {
    const tempId = generateTempId("temp-geb");
    const newGebrek = {
      gebrekid: tempId,
      omschrijving: "",
      toelichting: "",
      gebrekcode: null,
      locaties: [],
      media: [],
      gevaarlijkesituatie: [],
      prioriteit: PRIORITEIT_OPTIONS[0],
      kleur: null,
    };

    if (initialLocations && Array.isArray(initialLocations)) {
      newGebrek.locaties = initialLocations.map((loc) => ({
        ...loc,
        locatieid: generateTempId("temp-loc"),
      }));
    }

    gebrekRefs.current[tempId] = React.createRef();

    setInspection((prev) => ({
      ...prev,
      gebreken: [...(prev?.gebreken || []), newGebrek],
    }));

    setCollapsedGebreken((prev) => ({ ...prev, [tempId]: false }));

    setTimeout(() => {
      gebrekRefs.current[tempId]?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  }, []);

  const copyEntireGebrek = useCallback((gebrekToCopy) => {
    const tempId = generateTempId("temp-geb");
    const gebrekCopy = JSON.parse(JSON.stringify(gebrekToCopy));

    const newGebrek = {
      ...gebrekCopy,
      gebrekid: tempId,
      locaties:
        gebrekCopy.locaties?.map((loc) => ({
          ...loc,
          locatieid: generateTempId("temp-loc"),
        })) || [],
      media: [],
      gevaarlijkesituatie: [],
    };

    gebrekRefs.current[tempId] = React.createRef();

    setInspection((prev) => ({
      ...prev,
      gebreken: [...(prev?.gebreken || []), newGebrek],
    }));

    setCollapsedGebreken((prev) => ({ ...prev, [tempId]: false }));

    setTimeout(() => {
      gebrekRefs.current[tempId]?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  }, []);

  const deleteGebrek = useCallback(
    (gebrekIdToDelete) => {
      // Find the gebrek object *before* showing confirmation
      const gebrekToDelete = inspection?.gebreken.find(
        (g) => g.gebrekid === gebrekIdToDelete
      );

      Swal.fire({
        title: "Weet u het zeker?",
        text: "U staat op het punt dit gebrek te verwijderen (lokaal). De definitieve verwijdering gebeurt bij het opslaan.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Ja, verwijder lokaal",
        cancelButtonText: "Annuleren",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setInspection((prev) => {
            if (!prev || !prev.gebreken) return prev;
            return {
              ...prev,
              gebreken: prev.gebreken.filter(
                (g) => g.gebrekid !== gebrekIdToDelete
              ),
            };
          });

          setCollapsedGebreken((prev) => {
            const newState = { ...prev };
            delete newState[gebrekIdToDelete];
            return newState;
          });
          delete gebrekRefs.current[gebrekIdToDelete];
          setNewLocationInputs((prev) => {
            const newState = { ...prev };
            delete newState[gebrekIdToDelete];
            return newState;
          });
          setUploadErrors((prev) => {
            const newState = { ...prev };
            delete newState[gebrekIdToDelete];
            return newState;
          });
          setGsUploadErrors((prev) => {
            const newState = { ...prev };
            gebrekToDelete?.gevaarlijkesituatie?.forEach((gs) => {
              delete newState[gs.gevaarlijkesituatieid];
            });
            return newState;
          });
          if (copiedLocationsData?.sourceGebrekId === gebrekIdToDelete) {
            setCopiedLocationsData(null);
          }

          Swal.fire({
            title: "Lokaal Verwijderd",
            text: "Het gebrek is lokaal verwijderd. Klik op 'Opslaan' om de wijziging definitief te maken.",
            icon: "info",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("Gebrek deletion cancelled by user.");
        }
      });
    },
    [inspection?.gebreken, copiedLocationsData?.sourceGebrekId]
  );

  const createNewGebrekWithLocations = useCallback(
    (gebrekToCopyLocationsFrom) => {
      if (
        !gebrekToCopyLocationsFrom?.locaties ||
        gebrekToCopyLocationsFrom.locaties.length === 0
      ) {
        Swal.fire({
          icon: "info",
          title: "Geen Locaties",
          text: "Dit gebrek heeft geen locaties om te kopiëren naar een nieuw gebrek.",
        });
        return;
      }
      const locationsCopy = JSON.parse(
        JSON.stringify(gebrekToCopyLocationsFrom.locaties)
      );
      addGebrek(locationsCopy);
    },
    [addGebrek]
  );

  // --- Location Copy/Paste Logic ---
  const copyLocationsForPasting = useCallback((gebrekId, locations) => {
    if (!locations || locations.length === 0) {
      Swal.fire({
        icon: "info",
        title: "Geen Locaties",
        text: "Er zijn geen locaties om te kopiëren.",
      });
      return;
    }
    const locationsCopy = JSON.parse(JSON.stringify(locations));
    setCopiedLocationsData({
      sourceGebrekId: gebrekId,
      locations: locationsCopy,
    });
    Swal.fire({
      icon: "success",
      title: "Gekopieerd",
      text: `${locations.length} locatie(s) gekopieerd voor plakken.`,
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
    });
  }, []);

  const pasteLocationsIntoGebrek = useCallback(
    (targetGebrekIndex, targetGebrekId) => {
      if (!copiedLocationsData?.locations?.length) {
        Swal.fire({
          icon: "warning",
          title: "Niets om te Plakken",
          text: "Er zijn geen locaties gekopieerd om te plakken.",
        });
        return;
      }

      let pastedCount = 0;
      let alreadyExistCount = 0;

      setInspection((prev) => {
        if (!prev || !prev.gebreken) return prev;
        const updatedGebreken = [...prev.gebreken];
        const targetGebrek = updatedGebreken[targetGebrekIndex];

        if (targetGebrek?.gebrekid === targetGebrekId) {
          const existingLocationNames = new Set(
            targetGebrek.locaties?.map((l) => l.locatienaam) || []
          );
          const locationsToPaste = [];

          copiedLocationsData.locations.forEach((loc) => {
            if (!existingLocationNames.has(loc.locatienaam)) {
              locationsToPaste.push({
                ...loc,
                locatieid: generateTempId("temp-loc"),
              });
            } else {
              alreadyExistCount++;
            }
          });

          pastedCount = locationsToPaste.length;

          if (pastedCount > 0) {
            updatedGebreken[targetGebrekIndex] = {
              ...targetGebrek,
              locaties: [...(targetGebrek.locaties || []), ...locationsToPaste],
            };
          }
        } else {
          console.warn("Target gebrek mismatch during paste");
        }
        return { ...prev, gebreken: updatedGebreken };
      });

      // Show appropriate message after state update attempt
      if (pastedCount > 0) {
        Swal.fire({
          icon: "success",
          title: "Geplakt",
          text: `${pastedCount} unieke locatie(s) geplakt. ${
            alreadyExistCount > 0 ? `${alreadyExistCount} bestonden al.` : ""
          }`,
        });
      } else if (alreadyExistCount > 0) {
        Swal.fire({
          icon: "info",
          title: "Locaties Bestaan Al",
          text: "Alle gekopieerde locaties bestaan al in dit gebrek.",
        });
      }
    },
    [copiedLocationsData]
  );

  // --- Location Add Logic ---
  const handleNewLocationInputChange = useCallback((gebrekId, value) => {
    setNewLocationInputs((prev) => ({ ...prev, [gebrekId]: value }));
  }, []);

  const addLocationToGebrek = useCallback(
    (gebrekIndex, gebrekId) => {
      const newLocationName = (newLocationInputs[gebrekId] || "").trim();
      if (!newLocationName) {
        Swal.fire({
          icon: "warning",
          title: "Locatienaam Vereist",
          text: "Voer een locatienaam in.",
        });
        return;
      }

      const newLocation = {
        locatieid: generateTempId("temp-loc"),
        locatienaam: newLocationName,
      };

      setInspection((prev) => {
        if (!prev || !prev.gebreken || !prev.gebreken[gebrekIndex]) return prev;
        const updatedGebreken = [...prev.gebreken];
        updatedGebreken[gebrekIndex] = {
          ...updatedGebreken[gebrekIndex],
          locaties: [
            ...(updatedGebreken[gebrekIndex].locaties || []),
            newLocation,
          ],
        };
        return { ...prev, gebreken: updatedGebreken };
      });

      handleNewLocationInputChange(gebrekId, "");
    },
    [newLocationInputs, handleNewLocationInputChange]
  );

  // --- Error State Setters ---
  const handleSetUploadError = useCallback((gebrekId, errorMessage) => {
    setUploadErrors((prev) => ({ ...prev, [gebrekId]: errorMessage }));
  }, []);

  const handleSetGsUploadError = useCallback((situatieId, errorMessage) => {
    setGsUploadErrors((prev) => ({ ...prev, [situatieId]: errorMessage }));
  }, []);

  // --- Submit Logic ---
  const handleSubmit = useCallback(async () => {
    if (!inspection || isSubmitting) return;

    setIsSubmitting(true);
    setError(null);

    // Show loading Swal
    Swal.fire({
      title: "Bezig met opslaan...",
      text: "Een ogenblik geduld.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const updatedInspectionData = JSON.parse(JSON.stringify(inspection));
      console.log("Submitting data:", updatedInspectionData);

      const response = await update_inspectie(updatedInspectionData);

      if (!response.ok) {
        let errorDetails = `Status: ${response.status}`;
        try {
          const errorData = await response.json();
          errorDetails = errorData.message || JSON.stringify(errorData);
        } catch (parseError) {
          errorDetails = `${errorDetails} - ${
            response.statusText || "Unknown error"
          }`;
        }
        throw new Error(`Failed to update inspection. ${errorDetails}`);
      }

      // Close loading Swal and show success
      Swal.fire({
        icon: "success",
        title: "Opgeslagen!",
        text: "Inspectie is succesvol bijgewerkt.",
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      }).then(() => {
        navigate("/inspections"); // Navigate after Swal closes
      });
    } catch (err) {
      console.error("Error updating inspection:", err);
      const errorMessage =
        err.message ||
        "Er is een fout opgetreden bij het opslaan van de inspectie.";
      setError(errorMessage);
      // Close loading Swal and show error
      Swal.fire({
        icon: "error",
        title: "Opslaan Mislukt",
        text: errorMessage,
      });
    } finally {
      setIsSubmitting(false);
      // Ensure loading Swal is closed even if navigation doesn't happen
      if (Swal.isLoading()) {
        Swal.close();
      }
    }
  }, [inspection, isSubmitting, navigate]);

  // --- Return values ---
  return {
    inspection,
    isLoading,
    isSubmitting,
    error,
    fetchError,
    uploadErrors,
    gsUploadErrors,
    collapsedGebreken,
    gebrekRefs,
    newLocationInputs,
    copiedLocationsData,
    // Actions
    fetchInspection,
    handleUpdateGebrek,
    handleGebrekChange,
    toggleGebrekCollapse,
    collapseAllGebreken,
    expandAllGebreken,
    addGebrek,
    deleteGebrek,
    createNewGebrekWithLocations,
    copyLocationsForPasting,
    pasteLocationsIntoGebrek,
    handleNewLocationInputChange,
    addLocationToGebrek,
    handleSetUploadError,
    handleSetGsUploadError,
    handleSubmit,
    copyEntireGebrek,
  };
};
