import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { insert_object, delete_object } from "../../functions/api";

import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";

function FunctiesForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const initialObjectFunctieState = {
    objectfunctieid: location.state?.objectfunctie?.objectfunctieid ?? 0,
    naam: "",
    active: 1,
  };

  const [objectfunctie, setObjectFunctie] = useState(
    location.state?.objectfunctie ?? initialObjectFunctieState
  );
  const [isEditing, setIsEditing] = useState(
    !!location.state?.objectfunctie?.objectfunctieid || !!id
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.state?.objectfunctie) {
      setObjectFunctie(location.state.objectfunctie);
      setIsEditing(true);
    } else if (id) {
      setIsEditing(true);
      console.warn(
        "Editing mode detected from URL, but data fetching not implemented."
      );
    } else {
      setObjectFunctie(initialObjectFunctieState);
      setIsEditing(false);
    }
  }, [location.state, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setObjectFunctie((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const saveData = async () => {
    setLoading(true);
    try {
      objectfunctie.active = 1;

      const response = await insert_object(objectfunctie);

      if (response && response.ok) {
        Swal.fire({
          title: "Succes!",
          text: "Object functie succesvol opgeslagen!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        navigate("/administration/functies");
      } else {
        throw new Error(response?.message || "Opslaan mislukt");
      }
    } catch (error) {
      console.error("Error saving object functie:", error);
      Swal.fire({
        title: "Fout!",
        text: `Opslaan van object functie mislukt. ${error.message || ""}`,
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    const idToDelete = objectfunctie.objectfunctieid || parseInt(id, 10);
    if (!idToDelete) return;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2",
        cancelButton: "btn btn-danger mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Weet je zeker dat je deze functie wil verwijderen?",
        text: "Dit kan niet ongedaan worden gemaakt.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ja, Verwijder!",
        cancelButtonText: "Nee, Annuleer!",
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          try {
            const response = await delete_object(idToDelete);

            if (response && response.ok) {
              Swal.fire({
                title: "Verwijderd!",
                text: "Object functie succesvol verwijderd.",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
              navigate("/administration/functies");
            } else {
              throw new Error(response?.message || "Verwijderen mislukt");
            }
          } catch (error) {
            console.error("Error deleting object functie:", error);
            Swal.fire({
              title: "Fout!",
              text: `Verwijderen van object functie mislukt. ${
                error.message || ""
              }`,
              icon: "error",
            });
          } finally {
            setLoading(false);
          }
        }
      });
  };

  const labelStyle = "block text-sm font-medium text-gray-700 mb-1";
  const inputStyle =
    "block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-gray-100";
  const formGroupStyle = "mb-4";

  return (
    <div className="select-report-container-main bg-gray-100 min-h-screen">
      <Nav
        page={
          isEditing ? "Object Functie Bewerken" : "Object Functie Toevoegen"
        }
      />
      <div className="page-wrap-row flex">
        <Menu className="menu-container" />
        <div className="ml-[37px] md:ml-[163px] lg:ml-[189px] mt-[62px] w-full p-6 text-black">
          <h1 className="text-2xl font-semibold mb-4">
            {isEditing ? "Object Functie" : "Nieuwe Object Functie"}
          </h1>
          <hr className="hr2 mb-6" />

          <div className="max-w-md">
            <div className={formGroupStyle}>
              <label htmlFor="naam" className={labelStyle}>
                Waarde:
              </label>
              <input
                type="text"
                id="naam"
                name="naam"
                value={objectfunctie.naam}
                onChange={handleInputChange}
                className={inputStyle}
                disabled={loading}
                required
              />
            </div>
          </div>

          <hr className="hr2 mt-6 mb-4" />
          <div className="flex gap-4 justify-start items-center">
            <button
              type="button"
              onClick={saveData}
              disabled={loading || !objectfunctie.naam?.trim()}
              className="px-4 py-2 border rounded-md shadow-sm bg-green-500 text-white hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading
                ? "Opslaan..."
                : isEditing
                ? "Wijzigingen Opslaan"
                : "Functie Opslaan"}
            </button>
            <button
              type="button"
              onClick={() => navigate("/administration/functies")}
              disabled={loading}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              Terug
            </button>
            {isEditing && (
              <button
                type="button"
                onClick={handleDelete}
                disabled={loading}
                className="px-4 py-2 border rounded-md shadow-sm bg-red-500 text-white hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed ml-auto" // Ensure ml-auto is here
              >
                Verwijderen
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FunctiesForm;
