import React, { useState, useEffect, useCallback } from "react"; // Added useCallback
import { useNavigate } from "react-router-dom";

import "../../scss/registration/registration_forum.scss";

import "bootstrap/dist/css/bootstrap.min.css";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { upload_image } from "../../functions/api.js";
import { register } from "../../functions/api.js";

const Registration_forum = () => {
  const [scopeActive, setScopeActive] = useState([]);
  const [passwordShown, setPasswordShown] = useState("");
  const [form_step, set_form_step] = useState(1);
  const [validationErrors, setValidationErrors] = useState({});
  const [registerResponse, setRegisterResponse] = useState(null);
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logoValidationStatus, setLogoValidationStatus] = useState("initial");

  const navigate = useNavigate();

  const [userInput, setUserInput] = useState({
    form_step_1: {
      company_name: "",
      first_name: "",
      infix: "",
      last_name: "",
      email: "",
      phone_number: "",
      password1: "",
      password2: "",
    },
    form_step_2: {
      adress: "",
      postal_code: "",
      city: "",
      country: "",
      vat_number: "",
      logo: "",
      terms: false,
    },
    form_step_3: {
      found_message: "",
      scopes: [],
    },
  });

  const addUserInput = useCallback((stepKey, inputName, inputValue) => {
    setUserInput((prevInput) => ({
      ...prevInput,
      [stepKey]: {
        ...prevInput[stepKey],
        [inputName]: inputValue,
      },
    }));
  }, []);

  const handleInputChange = useCallback(
    (stepKey, event) => {
      const { name, value, type, checked } = event.target;
      const inputValue = type === "checkbox" ? checked : value;
      addUserInput(stepKey, name, inputValue);

      setValidationErrors((prevErrors) => {
        const stepErrors = { ...(prevErrors[stepKey] || {}) };
        delete stepErrors[name];
        return { ...prevErrors, [stepKey]: stepErrors };
      });

      if (name === "logoFile") {
        handleLogoChange(event);
      }
    },
    [addUserInput]
  );

  const handleClosePasswordRequirements = () =>
    setShowPasswordRequirements(false);
  const handleShowPasswordRequirements = () =>
    setShowPasswordRequirements(true);

  const togglePassword = (passwordField) => {
    setPasswordShown((prev) => (prev === passwordField ? "" : passwordField));
  };

  const addScope = (scopeName) => {
    setScopeActive((prevScopes) => {
      if (prevScopes.includes(scopeName)) {
        return prevScopes.filter((s) => s !== scopeName);
      } else {
        return [...prevScopes, scopeName];
      }
    });
  };

  const validateFormStep = useCallback(
    (step) => {
      const errors = {};
      const data = userInput[`form_step_${step}`];

      if (step === 1) {
        if (!data.company_name.trim())
          errors.company_name = "Verplichte invoer";
        if (!data.first_name.trim()) errors.first_name = "Verplichte invoer";
        if (!data.last_name.trim()) errors.last_name = "Verplichte invoer";

        const emailRegex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!data.email.trim()) {
          errors.email = "Verplichte invoer";
        } else if (!emailRegex.test(data.email)) {
          errors.email = "Geen geldige email";
        }

        const phoneRegex =
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        if (!data.phone_number.trim()) {
          errors.phone_number = "Verplichte invoer";
        } else if (!phoneRegex.test(data.phone_number)) {
          errors.phone_number = "Geen geldig telefoonnummer";
        }

        const passwordRegex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
        if (!data.password1) {
          errors.password1 = "Verplichte invoer";
        } else if (!passwordRegex.test(data.password1)) {
          errors.password1 = "Wachtwoord niet sterk genoeg";
        }

        if (!data.password2) {
          errors.password2 = "Verplichte invoer";
        } else if (data.password1 && data.password1 !== data.password2) {
          errors.password1 = "Wachtwoord komt niet overheen";
          errors.password2 = "Wachtwoord komt niet overheen";
        } else if (
          data.password2 &&
          !errors.password1 &&
          !passwordRegex.test(data.password2)
        ) {
          errors.password2 = "Wachtwoord niet sterk genoeg";
        }
      } else if (step === 2) {
        // Step 2 Validation
        if (!data.adress.trim()) errors.adress = "Verplichte invoer";
        if (!data.postal_code.trim()) errors.postal_code = "Verplichte invoer";
        if (!data.city.trim()) errors.city = "Verplichte invoer";
        if (!data.country.trim()) errors.country = "Verplichte invoer";
        if (!data.vat_number.trim()) errors.vat_number = "Verplichte invoer";
        if (!data.logo) errors.logo = "Logo is verplicht";
        if (!data.terms) errors.terms = "U moet de voorwaarden accepteren";
      }

      setValidationErrors((prev) => ({
        ...prev,
        [`form_step_${step}`]: errors,
      }));
      return Object.keys(errors).length === 0;
    },
    [userInput]
  );

  const handleLogoChange = useCallback(
    async (event) => {
      const img = event.target.files[0];
      if (!img) {
        setLogoValidationStatus("initial");
        addUserInput("form_step_2", "logo", "");
        return;
      }

      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.test(img.name)) {
        setLogoValidationStatus("invalid-type");
        addUserInput("form_step_2", "logo", "");
        event.target.value = null;
        return;
      }

      setLogoValidationStatus("uploading");
      try {
        const data = await upload_image(img);
        if (data?.response?.mediaid) {
          addUserInput("form_step_2", "logo", data.response.mediaid);
          setLogoValidationStatus("valid");
          setValidationErrors((prevErrors) => {
            const step2Errors = { ...(prevErrors.form_step_2 || {}) };
            delete step2Errors.logo;
            return { ...prevErrors, form_step_2: step2Errors };
          });
        } else {
          console.error("Image upload failed:", data);
          setLogoValidationStatus("invalid");
          addUserInput("form_step_2", "logo", "");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        setLogoValidationStatus("invalid");
        addUserInput("form_step_2", "logo", "");
      }
    },
    [addUserInput]
  );

  const nextFormStep = useCallback(async () => {
    if (isSubmitting) return;

    let isValid = false;
    if (form_step === 1) {
      isValid = validateFormStep(1);
    } else if (form_step === 2) {
      isValid = validateFormStep(2);
      if (logoValidationStatus !== "valid") {
        isValid = false;
        if (!validationErrors.form_step_2?.logo) {
          setValidationErrors((prev) => ({
            ...prev,
            form_step_2: {
              ...(prev.form_step_2 || {}),
              logo:
                logoValidationStatus === "invalid-type"
                  ? "Ongeldig bestandstype"
                  : "Logo uploaden mislukt of niet geselecteerd",
            },
          }));
        }
      }
    } else if (form_step === 3) {
      isValid = true;
    }

    if (!isValid) {
      return;
    }

    if (form_step < 3) {
      set_form_step((prevStep) => prevStep + 1);
    } else {
      setIsSubmitting(true);
      setRegisterResponse(null);

      const finalUserInput = {
        ...userInput,
        form_step_3: {
          ...userInput.form_step_3,
          scopes: scopeActive,
        },
      };

      try {
        const response = await register(finalUserInput);
        setRegisterResponse(response);

        if (response?.message === "account-created") {
          sessionStorage.setItem("registrationStatus", "success");
          navigate("/finish");
        } else {
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Registration failed:", error);
        setRegisterResponse({ message: "registration-failed-error" });
        setIsSubmitting(false);
      }
    }
  }, [
    form_step,
    validateFormStep,
    isSubmitting,
    navigate,
    userInput,
    scopeActive,
    logoValidationStatus,
    validationErrors.form_step_2?.logo,
  ]);

  useEffect(() => {
    const listener = (event) => {
      if (
        event.code === "Enter" &&
        !showPasswordRequirements &&
        event.target.tagName !== "TEXTAREA"
      ) {
        event.preventDefault();
        nextFormStep();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [nextFormStep, showPasswordRequirements]);

  const getInputClassName = (step, fieldName) => {
    const baseClass = "input-color";
    const errorClass = "validationError";
    return validationErrors[`form_step_${step}`]?.[fieldName]
      ? errorClass
      : baseClass;
  };

  const getLogoWrapperClassName = () => {
    if (validationErrors.form_step_2?.logo) return "validationError";
    switch (logoValidationStatus) {
      case "valid":
        return "validationSuccess";
      case "invalid":
      case "invalid-type":
        return "validationError";
      case "uploading":
        return "input-color uploading";
      default:
        return "input-color";
    }
  };

  return (
    <div>
      <div className="registration-title">
        {[1, 2, 3].map((step) => (
          <div
            key={step}
            onClick={() => form_step >= step && set_form_step(step)}
            className={`forum-step ${form_step >= step ? "active" : ""}`}
            style={{ cursor: form_step >= step ? "pointer" : "default" }}
          >
            <p>Stap-{step}</p>
          </div>
        ))}
      </div>

      <div className="registration-body">
        <div
          style={form_step === 1 ? { display: "block" } : { display: "none" }}
        >
          <div className="input-section">
            <label className="login-lbl" htmlFor="company-name">
              Bedrijfsnaam
            </label>
            <div className={getInputClassName(1, "company_name")}>
              <input
                type="text"
                id="company-name"
                name="company_name"
                value={userInput.form_step_1.company_name}
                onChange={(e) => handleInputChange("form_step_1", e)}
                autoComplete="organization"
              />
            </div>
            <p className="validationMessage">
              {validationErrors.form_step_1?.company_name}
            </p>
          </div>

          <div className="input-section-row">
            <div className="input-wrapper">
              <label className="login-lbl" htmlFor="first-name">
                Voornaam
              </label>
              <div className={getInputClassName(1, "first_name")}>
                <input
                  type="text"
                  id="first-name"
                  name="first_name"
                  value={userInput.form_step_1.first_name}
                  onChange={(e) => handleInputChange("form_step_1", e)}
                  autoComplete="given-name"
                />
              </div>
              <p className="validationMessage">
                {validationErrors.form_step_1?.first_name}
              </p>
            </div>
            <div className="input-wrapper">
              <label className="login-lbl" htmlFor="infix">
                Tussenvoegsel
              </label>
              <div className="input-color">
                <input
                  type="text"
                  id="infix"
                  name="infix"
                  value={userInput.form_step_1.infix}
                  onChange={(e) => handleInputChange("form_step_1", e)}
                  autoComplete="additional-name"
                />
              </div>
            </div>
            <div className="input-wrapper">
              <label className="login-lbl" htmlFor="last-name">
                Achternaam
              </label>
              <div className={getInputClassName(1, "last_name")}>
                <input
                  type="text"
                  id="last-name"
                  name="last_name"
                  value={userInput.form_step_1.last_name}
                  onChange={(e) => handleInputChange("form_step_1", e)}
                  autoComplete="family-name"
                />
              </div>
              <p className="validationMessage">
                {validationErrors.form_step_1?.last_name}
              </p>
            </div>
          </div>

          <div className="input-section">
            <label className="login-lbl" htmlFor="email">
              Email
            </label>
            <div className={getInputClassName(1, "email")}>
              <input
                type="email"
                id="email"
                name="email"
                value={userInput.form_step_1.email}
                onChange={(e) => handleInputChange("form_step_1", e)}
                autoComplete="email"
              />
            </div>
            <p className="validationMessage">
              {validationErrors.form_step_1?.email}
            </p>
          </div>

          <div className="input-section">
            <label className="login-lbl" htmlFor="phone_number">
              Telefoonnummer
            </label>
            <div className={getInputClassName(1, "phone_number")}>
              <input
                type="tel"
                id="phone_number"
                name="phone_number"
                value={userInput.form_step_1.phone_number}
                onChange={(e) => handleInputChange("form_step_1", e)}
                autoComplete="tel"
              />
            </div>
            <p className="validationMessage">
              {validationErrors.form_step_1?.phone_number}
            </p>
          </div>

          <div className="input-section-row">
            <div className="input-wrapper">
              <label className="login-lbl" htmlFor="password">
                Wachtwoord{" "}
                <i
                  onClick={handleShowPasswordRequirements}
                  className="fa-solid fa-circle-question"
                  style={{ cursor: "pointer" }}
                ></i>
              </label>
              <div className={getInputClassName(1, "password1")}>
                <input
                  type={passwordShown === "1" ? "text" : "password"}
                  id="password"
                  name="password1"
                  value={userInput.form_step_1.password1}
                  onChange={(e) => handleInputChange("form_step_1", e)}
                  autoComplete="new-password"
                />
                <div
                  onClick={() => togglePassword("1")}
                  className="password-toggle"
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={
                      passwordShown === "1"
                        ? "fa-solid fa-eye"
                        : "fa-solid fa-eye-slash"
                    }
                  ></i>
                </div>
              </div>
              <p className="validationMessage">
                {validationErrors.form_step_1?.password1}
              </p>
            </div>
            <div className="input-wrapper">
              <label className="login-lbl" htmlFor="repeat-password">
                Herhaal wachtwoord
              </label>
              <div className={getInputClassName(1, "password2")}>
                <input
                  type={passwordShown === "2" ? "text" : "password"}
                  id="repeat-password"
                  name="password2" // Match state key
                  value={userInput.form_step_1.password2}
                  onChange={(e) => handleInputChange("form_step_1", e)}
                  autoComplete="new-password"
                />
                <div
                  onClick={() => togglePassword("2")}
                  className="password-toggle"
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={
                      passwordShown === "2"
                        ? "fa-solid fa-eye"
                        : "fa-solid fa-eye-slash"
                    }
                  ></i>
                </div>
              </div>
              <p className="validationMessage">
                {validationErrors.form_step_1?.password2}
              </p>
            </div>
          </div>
        </div>

        <div
          style={form_step === 2 ? { display: "block" } : { display: "none" }}
        >
          <div className="input-section">
            <label className="login-lbl" htmlFor="adress">
              Adres
            </label>
            <div className={getInputClassName(2, "adress")}>
              <input
                type="text"
                id="adress"
                name="adress"
                value={userInput.form_step_2.adress}
                onChange={(e) => handleInputChange("form_step_2", e)}
                autoComplete="street-address"
              />
            </div>
            <p className="validationMessage">
              {validationErrors.form_step_2?.adress}
            </p>
          </div>

          <div className="input-section-row">
            <div className="input-wrapper">
              <label className="login-lbl" htmlFor="postalcode">
                Postcode
              </label>
              <div className={getInputClassName(2, "postal_code")}>
                <input
                  type="text"
                  id="postalcode"
                  name="postal_code"
                  value={userInput.form_step_2.postal_code}
                  onChange={(e) => handleInputChange("form_step_2", e)}
                  autoComplete="postal-code"
                />
              </div>
              <p className="validationMessage">
                {validationErrors.form_step_2?.postal_code}
              </p>
            </div>
            <div className="input-wrapper">
              <label className="login-lbl" htmlFor="city">
                Stad
              </label>
              <div className={getInputClassName(2, "city")}>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={userInput.form_step_2.city}
                  onChange={(e) => handleInputChange("form_step_2", e)}
                  autoComplete="address-level2"
                />
              </div>
              <p className="validationMessage">
                {validationErrors.form_step_2?.city}
              </p>
            </div>
          </div>

          <div className="input-section-row">
            <div className="input-wrapper">
              <label className="login-lbl" htmlFor="country">
                Land
              </label>
              <div className={getInputClassName(2, "country")}>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={userInput.form_step_2.country}
                  onChange={(e) => handleInputChange("form_step_2", e)}
                  autoComplete="country-name"
                />
              </div>
              <p className="validationMessage">
                {validationErrors.form_step_2?.country}
              </p>
            </div>
            <div className="input-wrapper">
              <label className="login-lbl" htmlFor="vatnumber">
                BTW nummer
              </label>
              <div className={getInputClassName(2, "vat_number")}>
                <input
                  type="text"
                  id="vatnumber"
                  name="vat_number"
                  value={userInput.form_step_2.vat_number}
                  onChange={(e) => handleInputChange("form_step_2", e)}
                />
              </div>
              <p className="validationMessage">
                {validationErrors.form_step_2?.vat_number}
              </p>
            </div>
          </div>

          <div className="input-section-row">
            <div className="input-wrapper">
              <label className="file" htmlFor="logo-file-input">
                Logo (JPEG, JPG, PNG, GIF)
              </label>{" "}
              <div className={getLogoWrapperClassName()}>
                <input
                  type="file"
                  accept=".jpeg,.jpg,.png"
                  onChange={handleLogoChange}
                  id="logo-file-input"
                  name="logoFile"
                  aria-label="Logo upload"
                />
                <span className="file-custom" />
              </div>
              <p className="validationMessage">
                {validationErrors.form_step_2?.logo ||
                  (logoValidationStatus === "invalid-type"
                    ? "Ongeldig bestandstype (alleen JPEG, PNG, GIF)"
                    : null) ||
                  (logoValidationStatus === "invalid"
                    ? "Fout bij uploaden logo"
                    : null)}
              </p>
            </div>
          </div>

          <div className="terms-wrapper">
            <input
              type="checkbox"
              id="terms-btn"
              name="terms"
              checked={userInput.form_step_2.terms}
              onChange={(e) => handleInputChange("form_step_2", e)}
            />
            <label
              htmlFor="terms-btn"
              className={
                validationErrors.form_step_2?.terms ? "validationErrorText" : ""
              }
            >
              Klik om de{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={
                  validationErrors.form_step_2?.terms
                    ? "validationErrorText"
                    : ""
                }
                href="https://www.ezinspect.nl/wp-content/uploads/2022/04/algemene_voorwaarden_ezinspect.pdf"
              >
                voorwaarden
              </a>{" "}
              te accepteren
            </label>
            {validationErrors.form_step_2?.terms && (
              <p
                className="validationMessage"
                style={{ marginLeft: "25px", marginTop: "5px" }}
              >
                {validationErrors.form_step_2.terms}
              </p>
            )}
          </div>
        </div>

        {/* --- Form Step 3 --- */}
        <div
          style={form_step === 3 ? { display: "block" } : { display: "none" }}
        >
          <div className="input-section">
            <label className="login-lbl" htmlFor="find-us-text">
              Hoe heeft u ons gevonden?
            </label>
            <div className="input-color">
              <textarea
                id="find-us-text"
                name="found_message"
                cols="30"
                rows="10"
                value={userInput.form_step_3.found_message}
                onChange={(e) => handleInputChange("form_step_3", e)}
              ></textarea>
            </div>
          </div>
          <div className="input-section">
            <label className="login-lbl">Met welke scopes wilt u werken?</label>
            <div className="scope-btn-wrapper">
              {["scope-8", "scope-10", "scope-NEN1010", "scope-12"].map(
                (scope) => (
                  <div
                    key={scope}
                    onClick={() => addScope(scope)}
                    className={`scope ${
                      scopeActive.includes(scope) ? "active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                  >
                    <p>{scope.replace("scope-", "Scope ")}</p>{" "}
                    {/* Nicer display */}
                  </div>
                )
              )}
            </div>
          </div>

          {registerResponse &&
            registerResponse.message !== "account-created" && (
              <div className="registration-error-message">
                <div className="text-wrapper">
                  <p>
                    {{
                      "account-already-exist":
                        "Dit account is al geregistreerd",
                      "invalid-email": "U heeft een ongeldig email ingevoerd",
                      "invalid-phone_number":
                        "U heeft een ongeldig telefoonnummer ingevoerd",
                      "password-not-equal":
                        "De ingevoerde wachtwoorden komen niet overheen",
                      "password-not-strong-enough":
                        "Het ingevoerde wachtwoord is niet sterk genoeg",
                      "registration-failed-error":
                        "Registratie mislukt. Probeer het opnieuw.",
                    }[registerResponse.message] ||
                      "Er is een onbekende fout opgetreden."}
                  </p>
                </div>
              </div>
            )}
        </div>
      </div>

      <div className="registration-footer">
        <div
          className={`footer-wrapper ${
            isSubmitting ? "loading" : "notLoading"
          }`}
        >
          <button
            onClick={nextFormStep}
            className="next-step-btn"
            disabled={isSubmitting}
          >
            {!isSubmitting && (
              <>
                {form_step < 3 ? "Verder" : "Registreren"}
                <svg
                  width="23"
                  height="16"
                  viewBox="0 0 23 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.7071 8.77595C23.0976 8.38543 23.0976 7.75227 22.7071 7.36174L16.3431 0.99778C15.9526 0.607255 15.3195 0.607255 14.9289 0.99778C14.5384 1.3883 14.5384 2.02147 14.9289 2.41199L20.5858 8.06885L14.9289 13.7257C14.5384 14.1162 14.5384 14.7494 14.9289 15.1399C15.3195 15.5304 15.9526 15.5304 16.3431 15.1399L22.7071 8.77595ZM0 9.06885H22V7.06885H0V9.06885Z"
                    fill="white"
                  />
                </svg>
              </>
            )}
            {isSubmitting && (
              <svg /* Loading SVG */
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  margin: "auto",
                  background: "none",
                  display: "block",
                  shapeRendering: "auto",
                  width: "30px", // Adjust size as needed
                  height: "30px",
                }}
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <g transform="rotate(0 50 50)">
                  <rect
                    x={47}
                    y={24}
                    rx={3}
                    ry={6}
                    width={6}
                    height={12}
                    fill="#ffffff"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="-0.9166666666666666s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
                {/* ... other g elements ... */}
                <g transform="rotate(330 50 50)">
                  <rect
                    x={47}
                    y={24}
                    rx={3}
                    ry={6}
                    width={6}
                    height={12}
                    fill="#ffffff"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="0s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
              </svg>
            )}
          </button>
        </div>
      </div>

      <Modal
        show={showPasswordRequirements}
        onHide={handleClosePasswordRequirements}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Uw wachtwoord moet voldoen aan de volgende eisen:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Minimaal 10 karakters</p>
          <p>Minimaal 1 hoofdletter</p>
          <p>Minimaal 1 kleine letter</p>
          <p>Minimaal 1 speciaal teken (@$!%*?&)</p>
          <p>Minimaal 1 cijfer</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePasswordRequirements}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Registration_forum;
