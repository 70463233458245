import React from "react";
import GebrekItem from "./GebrekItem";

const GebrekenList = ({
  inspection,
  collapsedGebreken,
  uploadErrors,
  gsUploadErrors,
  newLocationInputs,
  copiedLocationsData,
  gebrekRefs,
  onAddGebrek,
  onDeleteGebrek,
  onToggleGebrekCollapse,
  onCollapseAllGebreken,
  onExpandAllGebreken,
  onGebrekChange,
  onLocationInputChange,
  onAddLocation,
  onCreateNewGebrekWithLocations,
  onCopyLocationsForPasting,
  onPasteLocationsIntoGebrek,
  onUpdateGebrek,
  onSetUploadError,
  onSetGsUploadError,
  copyEntireGebrek,
}) => {
  const gebreken = inspection?.gebreken || [];

  return (
    <section className="flex flex-col space-y-4 border rounded-lg p-6 bg-gray-50 shadow">
      {/* Section Header */}
      <div className="flex justify-between items-center mb-2 border-b pb-2">
        <h2 className="text-xl font-semibold text-black">Gebreken</h2>
        <div className="flex items-center space-x-2">
          <button
            type="button"
            onClick={onCollapseAllGebreken}
            disabled={!gebreken.length}
            className="px-3 py-1 bg-gray-200 text-gray-700 text-xs font-semibold rounded-md shadow-sm hover:bg-gray-300 disabled:opacity-50"
          >
            Alles Inklappen
          </button>
          <button
            type="button"
            onClick={onExpandAllGebreken}
            disabled={!gebreken.length}
            className="px-3 py-1 bg-gray-200 text-gray-700 text-xs font-semibold rounded-md shadow-sm hover:bg-gray-300 disabled:opacity-50"
          >
            Alles Uitklappen
          </button>
          <button
            type="button"
            onClick={() => onAddGebrek()} // Add empty gebrek
            className="px-4 py-1 bg-green-500 text-white text-sm font-semibold rounded-md shadow-sm hover:bg-green-600"
          >
            + Nieuw Gebrek
          </button>
        </div>
      </div>

      {/* Gebreken List */}
      <div className="flex flex-col space-y-0">
        {gebreken.length > 0 ? (
          gebreken.map((gebrek, index) => (
            <GebrekItem
              key={gebrek.gebrekid}
              gebrek={gebrek}
              index={index}
              isCollapsed={collapsedGebreken[gebrek.gebrekid]}
              uploadError={uploadErrors[gebrek.gebrekid]}
              gsUploadErrors={gsUploadErrors}
              newLocationInput={newLocationInputs[gebrek.gebrekid]}
              copiedLocationsData={copiedLocationsData}
              gebrekRef={gebrekRefs.current[gebrek.gebrekid]}
              onToggleCollapse={onToggleGebrekCollapse}
              onDelete={onDeleteGebrek}
              onCopyEntireGebrek={copyEntireGebrek}
              onChange={onGebrekChange}
              onLocationInputChange={onLocationInputChange}
              onAddLocation={onAddLocation}
              onCreateNewWithLocations={onCreateNewGebrekWithLocations}
              onCopyLocations={onCopyLocationsForPasting}
              onPasteLocations={onPasteLocationsIntoGebrek}
              onUpdateGebrek={onUpdateGebrek}
              onSetUploadError={onSetUploadError}
              onSetGsUploadError={onSetGsUploadError}
            />
          ))
        ) : (
          <p className="text-gray-500 text-center py-4">
            Geen gebreken gevonden. Klik op '+ Nieuw Gebrek' om er een toe te
            voegen.
          </p>
        )}
      </div>
    </section>
  );
};

export default GebrekenList;
