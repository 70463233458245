import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { get_all_normen, delete_norm } from "../../functions/api";

import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";

function Normen() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState({
    field: "normid",
    order: "asc",
  });
  const [openActionsId, setOpenActionsId] = useState(null);

  const thStyle =
    "px-4 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider cursor-pointer";
  const tdStyle = "px-4 py-3 border-b border-gray-200 bg-white text-sm";

  useEffect(() => {
    setLoading(true);
    get_all_normen()
      .then((response) => {
        if (response.ok || response.status < 300) {
          return response.json();
        }
        throw response;
      })
      .then((apiData) => {
        const norms = apiData.result || apiData || [];
        setData(norms);
        setFilteredData(norms);
        handleSort(sortOrder.field, sortOrder.order, norms);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching normen:", error);
        Swal.fire({
          title: "Fout!",
          text: "Kon normen niet laden.",
          icon: "error",
        });
        setData([]);
        setFilteredData([]);
        setLoading(false);
      });
  }, []);

  const handleDeleteItem = (e, item) => {
    e.stopPropagation();
    setOpenActionsId(null);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2",
        cancelButton: "btn btn-danger mx-2",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Weet je zeker dat je deze norm wil verwijderen?",
        text: `Norm: ${
          item.omschrijving || item.normid
        }. Dit kan niet ongedaan worden gemaakt.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ja, Verwijder!",
        cancelButtonText: "Nee, Annuleer!",
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          delete_norm(item.normid)
            .then((response) => {
              if (
                response &&
                (response.ok ||
                  response.status < 300 ||
                  response.response ||
                  response.affectedRows > 0)
              ) {
                const updatedData = data.filter(
                  (d) => d.normid !== item.normid
                );
                setData(updatedData);
                const updatedFilteredData = filteredData.filter(
                  (d) => d.normid !== item.normid
                );
                setFilteredData(updatedFilteredData);

                Swal.fire({
                  title: "Verwijderd!",
                  text: "Norm succesvol verwijderd.",
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                throw new Error(response?.message || "Verwijderen mislukt");
              }
            })
            .catch((error) => {
              console.error("Error deleting norm:", error);
              Swal.fire({
                title: "Fout!",
                text: `Verwijderen mislukt. ${error.message || ""}`,
                icon: "error",
              });
            })
            .finally(() => setLoading(false));
        }
      });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    const lowercasedFilter = term.toLowerCase();
    const filtered = data.filter(
      (item) =>
        item.omschrijving?.toLowerCase().includes(lowercasedFilter) ||
        item.waarde?.toLowerCase().includes(lowercasedFilter) ||
        item.normid.toString().includes(lowercasedFilter)
    );
    handleSort(sortOrder.field, sortOrder.order, filtered);
  };

  const handleSort = (field, order, dataToSort = filteredData) => {
    const sortedData = [...dataToSort].sort((a, b) => {
      let valueA = a[field];
      let valueB = b[field];

      if (field === "normid") {
        valueA = Number(valueA);
        valueB = Number(valueB);
      } else if (typeof valueA === "string") {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      if (valueA < valueB) return order === "asc" ? -1 : 1;
      if (valueA > valueB) return order === "asc" ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedData);
    setSortOrder({ field, order });
  };

  const toggleSortOrder = (field) => {
    const newOrder =
      sortOrder.field === field && sortOrder.order === "asc" ? "desc" : "asc";
    handleSort(field, newOrder);
  };

  const resetFilters = () => {
    setSearchTerm("");
    const defaultSortField = "normid";
    const defaultSortOrder = "asc";
    setSortOrder({ field: defaultSortField, order: defaultSortOrder });
    handleSort(defaultSortField, defaultSortOrder, data);
  };

  const handleClickOutside = (event) => {
    if (
      openActionsId &&
      !event.target.closest(`#action-menu-${openActionsId}`) &&
      !event.target.closest(`#action-button-${openActionsId}`)
    ) {
      setOpenActionsId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openActionsId]);

  const renderTableRows = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan="4" className={`${tdStyle} text-center`}>
            {" "}
            Laden...
          </td>
        </tr>
      );
    }
    if (filteredData.length > 0) {
      return filteredData.map((item) => (
        <tr
          key={item.normid}
          className="hover:bg-gray-50 cursor-pointer transition-colors duration-150"
          onClick={() => {
            navigate(`/administration/normen/edit/${item.normid}`, {
              state: { norm: item },
            });
          }}
        >
          <td className={tdStyle}>{item.normid}</td>
          <td className={tdStyle}>{item.omschrijving}</td>
          <td className={tdStyle}>{item.toelichting}</td>
          <td className={`${tdStyle} text-center`}>
            <div className="relative">
              <button
                className="hover:bg-gray-200 rounded-lg p-1.5"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenActionsId(
                    openActionsId === item.normid ? null : item.normid
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icons-tabler-outline icon-tabler-dots"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                  <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                  <path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                </svg>
              </button>

              {openActionsId === item.normid && (
                <div
                  id={`action-menu-${item.normid}`}
                  className="absolute top-0 right-full mr-2 bg-white border border-gray-200 rounded-lg z-10 whitespace-nowrap"
                >
                  <div
                    className="flex gap-2 text-base hover:bg-red-500/20 hover:text-red-500 transition-all duration-150 px-2.5 py-1.5 rounded-lg cursor-pointer"
                    onClick={(e) => handleDeleteItem(e, item)} // Use generic handler
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-trash"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 7l16 0" />
                      <path d="M10 11l0 6" />
                      <path d="M14 11l0 6" />
                      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                    </svg>
                    Verwijderen
                  </div>
                </div>
              )}
            </div>
          </td>
        </tr>
      ));
    }
    return (
      <tr>
        <td colSpan="4" className={`${tdStyle} text-center`}>
          Geen normen gevonden
        </td>
      </tr>
    );
  };

  const getSortIndicator = (field) => {
    if (sortOrder.field === field) {
      return sortOrder.order === "asc" ? "▲" : "▼";
    }
    return "";
  };

  return (
    <div className="select-report-container-main">
      <Nav page="Normen Beheer" />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="select-report-container p-6 md:p-10">
          <h1 className="text-2xl font-semibold mb-4 ml-10 mt-10">
            Normen Overzicht
          </h1>
          <div className="px-10 mb-4 flex justify-between items-center">
            <input
              type="text"
              placeholder="Zoek op omschrijving, waarde..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 w-full sm:w-1/3"
            />
            <div className="flex flex-wrap gap-2">
              <button
                onClick={resetFilters}
                className="px-3 py-2 border border-transparent rounded-md shadow-sm text-sm bg-red-600 text-white hover:bg-red-700"
              >
                Reset Filters
              </button>
              <Link
                to="/administration/normen/create"
                className="px-4 py-2 border rounded-md shadow-sm text-white no-underline"
                style={{ backgroundColor: "#060a24" }}
              >
                Nieuwe Norm
              </Link>
            </div>
          </div>
          <div className="select-report-container-1">
            <div className="overflow-x-auto shadow rounded-lg">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      className={thStyle}
                      onClick={() => toggleSortOrder("normid")}
                    >
                      ID {getSortIndicator("normid")}
                    </th>
                    <th
                      className={thStyle}
                      onClick={() => toggleSortOrder("omschrijving")}
                    >
                      Omschrijving {getSortIndicator("omschrijving")}
                    </th>
                    <th
                      className={thStyle}
                      onClick={() => toggleSortOrder("waarde")}
                    >
                      Waarde {getSortIndicator("waarde")}
                    </th>
                    <th className={`${thStyle} text-center`}>Acties</th>
                  </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Normen;
